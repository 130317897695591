@import "mixins/gradients";

// Absolute position and cover
@mixin absolute-cover() {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

// generic transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

// translate
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}

// translate X only
@mixin translateX ($x) {
    @include transform(translateX($x));
}

// translate Y only
@mixin translateY ($y) {
    @include transform(translateY($y));
}

// translate 3d
@mixin translate3d($x, $y, $z) {
    -webkit-transform: translate3d($x, $y, $z);
    -moz-transform: translate3d($x, $y, $z);
    -o-transform: translate3d($x, $y, $z);
    transform: translate3d($x, $y, $z);
}

// transition
@mixin transition($x...) {
    -webkit-transition: $x;
    -moz-transition: $x;
    -ms-transition: $x;
    -o-transition: $x;
    transition: $x;
}

@mixin touch-hover() {
    .no-js &:hover, // the fallback
    .no-js &:focus, 
    .js.no-touch &:hover, // enhanced for no-touch
    .js.no-touch &:focus,
    .js.touch &:active { // relay same styles to active for touch devices
        @content; 
    }
}