/* Academics Program Page */

.faculty__image {
    width: 40%;
    float: left;

    @media #{$mobile} {
        width: 100%;
    }

    .img-fluid {
        max-width: 300px;
        width: 100%; 
        height: auto; 
        padding-bottom: 5px;
    }
}

.faculty__details {
    width: 60%;
    float: left;
    padding-left: 20px;

    @media #{$mobile} {
        margin-top: 20px;
        width: 100%;
        padding-left: 0;
    }

    .faculty-name {
        font-size: rem(30);
        font-weight: bold;

        @media #{$mobile} {
            font-size: rem(25);
        }
    }

    .faculty-subtitle {
        font-size: rem(22);
        font-weight: bold;
    }

    ul li {
        padding-left: 0;

        &:before {
            display: none;
        }

        span {
            padding-right: 10px;
        }
        
        a {
            border-bottom: 1px solid;

            &:hover {
                border-bottom: 1px solid transparent;
            }
        }
    }
}

.success-story__content {
    padding: 0 20px;

    @media #{$tablet} {
        padding: 0 10px;
    }

    @media #{$mobile} {
        padding: 20px 0 10px;
    }
}