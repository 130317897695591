/* Dates and Deadlines Page */

.table-responsive-sm {
    @media #{$mobile} {
        padding: 1px;

        > .table-bordered {
            border: 1px solid $gray__xl;
        }
        th {
            display: none;
        }

        td {
            display: block;

            &:before {
                content: attr(data-th)" ";
                color: $blue;
                font-size: rem(16);
                float: left;
                width: 50%;
                text-align: left;
            }

            &.table-responsive__content-left-aligned {
                text-align: left;
                margin-left: 10px;
                padding-left: 15px;
                word-wrap: normal;

                &:before {
                    width: 100%;
                    margin-left: -15px;
                }
            }
        }
    }
}

.table-responsive-xlg {
    @media #{$desktop_xlg} {
        padding: 1px;

        > .table-bordered {
            border: 1px solid $gray__xl;
        }
        th {
            display: none;
        }

        tbody tr {
            border-top: 1px solid $gray__xl;

            &:nth-child(even) {
                background: $gray__xxl;
            }
        }

        td, .table td {
            display: block;
            text-align: right;
            padding: 10px;
            overflow: auto;

            &:before {
                content: attr(data-th)" ";
                color: $blue;
                font-size: rem(16);
                float: left;
                width: 50%;
                text-align: left;
            }

            &.table-responsive__content-left-aligned {
                text-align: left;
                margin-left: 10px;
                padding-left: 15px;
                word-wrap: normal;

                &:before {
                    width: 100%;
                    margin-left: -15px;
                }
            }

        }
    }
}

.table-bordered {
    border: 1px solid $gray__xl;
}

.table {
    thead {
        tr {
            font-size: rem(20);

            @media #{$tablet_max} {
                font-size: rem(16);
            }
        }

        th {
            border: none;
            padding: 14px 15px;

            @media #{$tablet_max} {
                padding: 12px;
            }
        }
    }

    tbody tr {
        border-top: 1px solid $gray__xl;

        @media #{$mobile} {
            &:nth-child(even) {
                background: $gray__xxl;
            }
        }
    }

    td {
        border: none;
        font-size: rem(20);
        font-family: $font_main_roman;
        padding: 13px 15px;

        @media #{$tablet_max} {
            font-size: rem(16);
            padding: 12px;
        }

        a:not(.btn) {
            text-decoration: underline;
            &:hover {
                color: $blue;
                text-decoration: none;
            }
        }

        @media #{$mobile} {
            text-align: right;
            padding: 10px;
            overflow: auto;
        }
    }
}

.table_blue {
    thead {
        tr {
            background: $blue;
            color: $white;
        }
    }
}

.table_red {
    thead {
        tr {
            background: $red;
            color: $white;
        }
    }
}

.table_light-gray {
    thead {
        tr {
            background: $light-gray;
            color: $cool-gray;
        }
    }
}

.table_cool-gray {
    thead {
        tr {
            background: $cool-gray;
            color: $white;
        }
    }
}