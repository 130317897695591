/* Admissions Page */

nav .breadcrumb {
    padding-top: 5px;

    a {
        color: $blue;
        text-decoration: underline;

    }
}

.page-header {
    margin-bottom: 15px;

    @media #{$mobile} {
        margin-bottom: 20px;
    }
}

.page-title {
    font-size: rem(46);
    font-weight: bold;
    letter-spacing: -1px;
    position: relative;
    line-height: 1;

    @media #{$tablet} {
        font-size: rem(36);
    }

    @media #{$mobile} {
        font-size: rem(30);
    }

    .page-name {
        padding-right: 15px;
    }
}

.page__share {
    cursor: pointer;
    position: relative;
    padding-right: 20px;
    line-height: 0.6;
    display: inline-block;

    @media #{$mobile} {
        padding-right: 40px;
    }

    > a {
        color: $gray__xd;
        display: block;
        padding: 2px 5px;

        &.show .share-dropdown {
            display: block;
        }
    }

    .icon-share {
        font-size: rem(25);
        font-weight: bold;
        vertical-align: middle;
    }

    @include touch-hover() {
        .share-dropdown {
            display: block;
        }
    }

    &:hover {
        .share-dropdown {
            display: block;
        }
    }

    .share-dropdown {
        display: none;
        background: $gray__xl;
        position: absolute;
        padding: 10px;
        left: 80%;
        top: 50%;
        width: 140px;
        font-size: rem(25);
        z-index: 1;
        transform: translateY(-50%);

        &.show {
            display: block;
        }

        @media #{$mobile} {
            top: 77px;
            left: 0;

            .component-container &{
                top: 70px;
            }
        }

        &:before {
            content: "";
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-right: 8px solid $gray__xl;
            position: absolute;
            left: -8px;
            top: 50%;
            @include translateY(-50%);

            @media #{$mobile} {
                left: 5%;
                top: -4px;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
                border-bottom: 8px solid $gray__xl;
                border-top: none;
            }
        }

        a {
            color: $red;
            display: inline-block;
            margin: 5px;

            .fa, .fas, .fal, .fad, .fak {
                font-size: rem(28);
            }

            &:hover {
                color: $gray__xd;
            }
        }

        &.pull-left {
            left: -120%;

            &:before  {
                left: 71%;
            }
        }
    }
}

.uwg-links {
    li a {
        position: relative;
        color: $blue;
        transition: all 0.5s;
        display: block;
        padding-left: 25px;
        margin-top: 10px;

        +li {
            margin-top: 10px;
        }

        &:before {
            content: "\f105";
            font-family: 'Font Awesome 6 Pro';
            font-weight: 900;
            font-size: rem(20);
            position: absolute;
            top: -3px;
            transition: all 0.5s;
            left: 0;
        }

        &:hover {
            padding-left: 40px;

            &:before {
                left: 15px;
            }

            @media #{$tablet} {
                padding-left: 35px;
            }
        }

        a {
            color: $blue;
            display: block;

            @media #{$tablet} {
                padding-left: 20px;
            }

            &:hover, &:focus, &:visited, &:active {
                color: $gray__xd;

                &:before {
                    color: $gray__xd;
                }
            }
        }

        @media #{$tablet} {
            &:before {
                font-size: rem(18);
            }
        }
    }
}

.widget {
    .widget__row {
        flex: 1;
    }
}

.widget__box {
    >div {
        padding: 40px;

        @media #{$tablet} {
            padding: 20px;
            width: 100%;
        }

        @media #{$mobile_mini} {
            height: 100%;
        }
    }

    @media #{$mobile} {
        width: 100%;
        margin-bottom: 20px;
    }

    h2, .h2 {
        font-size: rem(38);
        font-family: $font_main_bdCon;
        letter-spacing: -1px;
        text-transform: uppercase;
        line-height: 1;

        @media #{$tablet} {
            font-size: rem(30);
        }
    }

    h3, .h3 {
        font-size: rem(25);
        font-weight: bold;

        @media #{$tablet} {
            font-size: rem(20);
        }
    }

    p {
        font-size: rem(20);
        font-family: $font_main_roman;
        line-height: 1.3;
        margin: 20px 0 30px;

        @media #{$tablet} {
            font-size: rem(16);
            line-height: 1.5;
        }
    }

    .border {
        border: 1px solid $gray__xl;
    }

    //a:not(.btn) {
    //    &:hover, &:focus {
    //        text-decoration: underline;
    //    }
    //
    //    .uwg-content & {
    //        border-bottom: none !important;
    //        &:hover, &:focus {
    //            text-decoration: underline !important;
    //        }
    //    }
    //}
}

.uwg-images {
    object-fit: cover;
}

.img-responsive {
    width: 100%;
}

.blue-title {
    color: $blue;
    font-size: rem(55);
    font-family: $font_main_bdCon;
    letter-spacing: -1px;
    line-height: 1;
    text-transform: uppercase;

    @media #{$tablet} {
        font-size: rem(44);
    }

    a {
        display: inline-block;

        &:hover {
            color: $blue;
            text-decoration: underline;
        }
    }
}

.content-block-with-image {
    border: 1px solid $gray__xl;
    margin: 40px 0;

    @media #{$tablet} {
        margin: 30px 0;
    }

    @media #{$mobile} {
        margin: 10px 0;
    }
}

.content-block__content {
    padding: 42px 35px 35px;

    @media #{$tablet} {
        padding: 30px;
    }

    @media #{$mobile} {
        padding: 30px 25px;
    }
}

.space-left {
    @media #{$tablet_sm_only} {
        margin-left: 8%;
    }
}

.space-right {
    @media #{$tablet_sm_only} {
        margin-right: 6%;
    }
}

.news_content {
    padding: 0 0 40px 50px;

    @media #{$tablet} {
        padding: 0 0 40px 40px;
    }

    @media #{$mobile} {
        padding: 30px 25px;
    }
}

.content-block__image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
    height: 100%;
    position: relative;

    .content-block-with-image & {
        min-height: 400px;

        @media #{$mobile} {
            min-height: 300px;
        }
    }

    img {
        @media #{$mobile} {
            width: 100%;
            height: 300px;

        }

        &.story-img {
            @media #{$tablet} {
                height: 210px;
            }
        }
    }
}

.image-pattern {
    background: url(../images/paintbrush-effect.png) no-repeat center center;
    background-size: 100%;
    width: 100%;
    height: 57px;
    position: absolute;
    right: 0;
    bottom: -26px;
}

.uwg-content {
    h1 {
        font-size: rem(36);
        font-weight: bold;
        letter-spacing: -0.8px;
        line-height: 1.1;
        margin-top: 0;

        @media #{$tablet} {
            font-size: rem(30);
        }
    }

    h3 {
        /*font-family: $font__main;*/
        font-size: rem(22);
        font-weight: bold;
        letter-spacing: -0.88px;
        line-height: 1.3;
        margin-bottom: 15px;

        @media #{$tablet} {
            font-size: rem(18);
        }
    }

    h4 {
        font-size: rem(20);
        line-height: 1.2;
        /*color: $gray__l;*/

        @media #{$tablet} {
            font-size: rem(16);
        }
    }

    h5{
        font-size: rem(18);
        line-height: 1.5;
        letter-spacing: -0.65px;
        margin-bottom: 5px;

        @media #{$tablet} {
            font-size: rem(14);
        }
    }


    a:not(.btn):not(.fancy-button):not(.video-play):not(.audio-play-btn) {
        border-bottom: 2px solid;
        text-decoration: none;
        transition: color 0.5s ease;

        &:hover, &:focus {
            border-bottom: 2px solid transparent;
            text-decoration: underline;
        }
    }

    .uwg-links a:not(.btn):not(.fancy-button):not(.video-play):not(.audio-play-btn) {
        border-bottom: 1px solid transparent;
        text-decoration: underline;

        &:hover, &:focus {
            border-bottom: 1px solid;
            text-decoration: none;
        }
    }

    a.audio-play-btn {
        border-bottom: none;
    }

    img.float-left {
        margin: 0 15px 15px 0;

        @media #{$mobile_mini} {
            width: 300px;
        }

        @media #{$mobile_sm_max} {
            width: 100%;
        }
    }

    img.float-right {
        margin: 0 0 15px 15px;

        @media #{$mobile_mini} {
            width: 300px;
        }

        @media #{$mobile_sm_max} {
            width: 100%;
        }
    }


    .figure {
        margin: 10px 0 30px;


        @media #{$mobile} {
            margin: 0 0 20px;
        }
    }
}

.figure-caption {
    font-size: rem(16);
    padding: 3px 0 10px;
    border-bottom: 1px solid $trueblack__xl;

    @media #{$mobile} {
        font-size: rem(13);
        padding: 0 0 5px;
    }
}

.uwg-sidebar {
    h2 {
        font-size: rem(25);
        font-weight: bold;
        line-height: 1;
        letter-spacing: -0.74px;

        @media #{$tablet_max} {
            font-size: rem(20);
            line-height: 1.3;
        }
    }
}

.underline {
    text-decoration: underline;
}