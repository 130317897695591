#cludo-404 {
  &.override {

    h3 {
      @extend h3;
      text-transform: initial;
      float: initial !important;
    }

    ul {
      margin: 10px 0;

      li {
        margin-bottom: 10px;
      }

    }

    .powered-by {
      border-bottom: none;
      text-decoration: none;
    }
  }
}

stencil-cludo-sayt {
  display:none !important;
}