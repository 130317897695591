// Object-position Helpers

.object-position-top {
  object-position: top !important;
}

.object-position-top-right {
  object-position: right top !important;
}

.object-position-right {
  object-position: right !important;
}

.object-position-bottom-right {
  object-position: right bottom !important;
}

.object-position-bottom {
  object-position: bottom !important;
}

.object-position-top-left {
  object-position: left top !important;
}

.object-position-left {
  object-position: left !important;
}

.object-position-bottom-left {
  object-position: left bottom !important;
}

.object-position-center {
  object-position: center !important;
}

// object-fit helpers

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-none {
  object-fit: none !important;
}

.object-fit-scale-down {
  object-fit: scale-down !important;
}