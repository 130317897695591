// stylelint-disable declaration-no-important

@each $color, $value in $theme-colors {
  @include bg-variant(".bg-#{$color}", $value);
}

@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
  }
}

.bg-white {
  background-color: $white !important;
}

.bg-transparent {
  background-color: transparent !important;
}

// background position helpers

.bg-position-top {
  background-position: top !important;
}

.bg-position-top-right {
  background-position: right top !important;
}

.bg-position-right {
  background-position: right !important;
}

.bg-position-bottom-right {
  background-position: right bottom !important;
}

.bg-position-bottom {
  background-position: bottom !important;
}

.bg-position-top-left {
  background-position: left top !important;
}

.bg-position-left {
  background-position: left !important;
}

.bg-position-bottom-left {
  background-position: left bottom !important;
}

.bg-position-center {
  background-position: center !important;
}

// background repeat helpers

.bg-repeat-repeat {
  background-repeat: repeat !important;
}

.bg-repeat-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-repeat-repeat-x {
  background-repeat: repeat-x !important;
}

.bg-repeat-repeat-y {
  background-repeat: repeat-y !important;
}

.bg-repeat-space {
  background-repeat: space !important;
}

.bg-repeat-round {
  background-repeat: round !important;
}

// background size helpers

.bg-size-contain {
  background-size: contain !important;
}

.bg-size-cover {
  background-size: cover !important;
}

.bg-size-100 {
  background-size: 100% !important;
}

.bg-size-50 {
  background-size: 50% !important;
}