// stylelint-disable declaration-no-important

// Width and height

@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
  }
}

.mw-100 { max-width: 100% !important; }
.mw-75  { max-width: 75% !important;  }
.mw-50  { max-width: 50% !important;  }

.mh-100 { max-height: 100% !important;}
.mh-57  { max-height: 75% !important; }
.mh-50  { max-height: 50% !important; }

// Viewport additional helpers

.min-vw-100 { min-width: 100vw !important; }
.min-vw-75 { min-width: 75vw !important; }
.min-vw-50 { min-width: 50vw !important; }

.min-vh-100 { min-height: 100vh !important; }
.min-vh-75 { min-height: 75vh !important; }
.min-vh-50 { min-height: 50vh !important; }

.vw-100 { width: 100vw !important; }
.vw-75 { width: 75vw !important; }
.vw-50 { width: 50vw !important; }

.vh-100 { height: 100vh !important; }
.vh-75 { height: 75vh !important; }
.vh-50 { height: 50vh !important; }

//  Width helpers

.w-33 { width: 33% !important; }

//  Additional screen-width specific helpers
// Width Medium
.w-md-33 {
  @media #{$tablet_min} { width: 33% !important; }
}
//Width Small
.w-sm-100 {
  @media #{$mobile_sm_max} { width: 100% !important; }
}
.w-sm-75 {
  @media #{$mobile_sm_max} { width: 75% !important; }
}
.w-sm-50 {
  @media #{$mobile_sm_max} { width: 50% !important; }
}
.w-sm-33 {
  @media #{$mobile_sm-max} { width: 33% !important; }
}
//Max-Height Medium
.mh-md-100 {
  @media #{$mobile_mini} { max-height: 100% !important; }
}
.mh-md-75 {
  @media #{$mobile_mini} { max-height: 75% !important; }
}
.mh-md-50 {
  @media #{$mobile_mini} { max-height: 50% !important; }
}
.mh-md-33 {
  @media #{$mobile_mini} { max-height: 33% !important; }
}