/* Academics College Page */

.text-red {
    color: $red;
}

.special-heading {
    font-size: rem(38);
    font-family: $font_main_con;
    line-height: 1;
    letter-spacing: -1px;
    text-transform: uppercase;
    font-weight: normal;
    margin-top: 33px;

    @media #{$tablet} {
        font-size: rem(30);
    }

    @media #{$mobile} {
        margin: 20px 0 30px;
    }
}

.special-subheading {
    font-size: rem(25);
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: -0.74px;
    margin-bottom: 15px;

    @media #{$tablet_max} {
        font-size: rem(20);
        margin-bottom: 13px;
    }
}

.college-contact-info {
    h3 {
        font-size: rem(25);
        font-weight: bold;
        color: $gray__l;
        margin-bottom: 10px;

        @media #{$tablet} {
            font-size: rem(20);
        }
    }
}

.gallery-container {
    padding: 50px 0 0;
    text-align: center;

    @media #{$mobile} {
        padding: 20px 0 0;
    }

    .slick-list {
        padding: 90px 0 60px !important;

        @media #{$tablet_lg} {
            padding: 30px 0 55px !important;
        }

        @media #{$tablet} {
            padding: 30px 0 45px !important;
        }
    }

    .slick-slide {
        transition: .5s ease-in-out all;
        position: relative;

        img {
            opacity: 0.6;

            &:hover {
                cursor: pointer;
            }
        }

        .gallery-info {
            opacity: 0;
            text-align: center;
            padding: 27px 20px 10px;
            transform: scale(0) translateY(-100%);
            transition: .2s ease-in-out all;
            max-width: 800px;

            @media #{$desktop_sm_only} {
                max-width: 600px;
            }

            @media #{$tablet_mini} {
                padding: 22px 20px 10px;
                max-width: 600px;
            }

            @media #{$tablet_sm_only} {
                padding: 22px 20px 10px;
                max-width: 550px;
            }

            @media #{$mobile} {
                padding: 8px 0;
                width: 60%;
                margin: 0 auto;
            }

            @media #{$mobile_sm_only} {
                max-width: 800px;
            }

            @media #{$mobile_sm} {
                width: 43%;
                min-height: 99px;
                max-width: 500px;
            }
        }

        @media #{$tablet_min} {

            &:nth-child(10)[aria-hidden="true"],
            &:nth-child(4)[aria-hidden="true"] {

                transform: scale(1.2);
                z-index: 10;

                &:before {
                    opacity: 0;
                    z-index: -1;
                }
            }
        }

        @media #{$mobile} {
            &:nth-child(2).slick-center {
                transform: scale(1.2);
                z-index: 10;

                &:before {
                    opacity: 0;
                    z-index: -1;
                }
            }

            &:nth-child(8)[aria-hidden="true"] {
                transform: scale(1.2);
                z-index: 10;

                &:before {
                    opacity: 0;
                    z-index: -1;
                }
            }
        }

        &.slick-current {
            transform: scale(1.2);
            z-index: 10;

            &:before {
                opacity: 0;
                z-index: -1;
            }

            img {
                opacity: 1;
            }

            .gallery-info {
                opacity: 1;
                transform: scale(1) translateY(0);

                @media #{$mobile} {
                    transform: scale(0.8);
                }
            }

            .gallery-pic {
                &.box-shadow {
                    box-shadow: 0 1.5rem 0.5rem -0.9rem rgba(0,0,0,0.7)  !important;
                }
            }

            +.slick-cloned {
                transform: scale(1) !important;
                z-index: 0;

                &:before {
                    opacity: 0.6;
                    z-index: 1;
                }
            }

            .gallery-name {
                display: block;
            }

            .read-more {
                display: inline-block;
            }
        }
    }

    .gallery-pic {
        width: auto;
        height: 500px;
        object-fit: cover;
        max-width: 1000px;
        margin: auto;

        @media #{$desktop_xlg} {
            height: 450px;
        }

        @media #{$desktop_lg} {
            height: 425px;
        }

        @media #{$tablet_lg} {
            height: 400px;
        }

        @media #{$tablet} {
            height: 312px;
        }

        @media #{$mobile} {
            height: 350px;
        }

        @media #{$mobile_sm} {
            height: 300px;
        }
    }

    &.slick-slider {

        .gallery-name,
        .read-more {
            display: none;
        }
    }

    .gallery-name {
        font-size: rem(34);
        font-weight: bold;
        color: $blue;

        @media #{$tablet} {
            font-size: rem(28);
        }

        @media #{$mobile} {
            font-size: rem(24);
        }

        @media #{$mobile_sm} {
            min-height: 53px;
        }

        a {
            color: $blue;
            display: inline-block;

            &:hover,
            &:focus {
                color: $blue;
            }
        }
    }

    .read-more {
        color: $blue;
        font-size: rem(16);
        font-weight: bold;
        padding: 5px;

        &:hover,
        &:focus {
            color: $gray__l;
        }
    }
}