@font-face {
    font-family: 'permanent_markerregular';
    font-display: auto;
    src: url('../fonts/permanentmarker-webfont.woff2') format('woff2'),
        url('../fonts/permanentmarker-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueLTStdBd';
    font-display: auto;
    src: url('../fonts/HelveticaNeueLTStdBd.eot');
    src: url('../fonts/HelveticaNeueLTStdBd.eot') format('embedded-opentype'),
        url('../fonts/HelveticaNeueLTStdBd.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTStdBd.woff') format('woff'),
        url('../fonts/HelveticaNeueLTStdBd.ttf') format('truetype'),
        url('../fonts/HelveticaNeueLTStdBd.svg#HelveticaNeueLTStdBd') format('svg');
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'HelveticaNeueLTStdLight';
    font-display: auto;
    font-style: normal;
    font-weight: 300;
    src: local('Helvetica Neue LT Std 45 Light'), url('../fonts/HelveticaNeueLTStd Lt.woff') format('woff');

}

@font-face {
    font-family: 'HelveticaNeueLTStdCon';
    font-display: auto;
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Neue LT Std 57 Condensed'), url('../fonts/HelveticaNeueLTStd-Cn.woff') format('woff');
}

@font-face {
    font-family: 'HelveticaNeueLTStdBoldCondensed';
    font-display: auto;
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Neue LT Std 77 Bold Condensed'), url('../fonts/HelveticaNeueLTStd-BdCn.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica_Heavy_Condensed';
    font-display: auto;
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica_Heavy_Condensed'), url('../fonts/HelveticaNeue-HeavyCond.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica_Roman';
    font-display: auto;
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica 55 Roman'), url('../fonts/HelveticaNeue-Roman.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica_Neue_Medium';
    font-display: auto;
    font-style: normal;
    font-weight: 500;
    src: local('Helvetica Neue Medium'), url('../fonts/HelveticaNeueMedium.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica_Black_Condensed';
    font-display: auto;
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica 97 Black Condensed'), url('../fonts/HelveticaNeue-BlackCond.woff') format('woff');
}

@font-face {
    font-family: 'HelveticaNeueLTStdConLight';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Neue LT Std 45 Light Condensed'), url('../fonts/HelveticaNeueLTStd-LtCn.ttf') format('truetype');
}
@font-face {
    font-family: 'HelveticaNeueLTStdConThin';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Neue LT Std 37 Thin Condensed'), url('../fonts/HelveticaNeueLTStd-ThCn.ttf') format('truetype');
}

@font-face {
    font-family: 'plumeplume';
    src: url('../fonts/plume-webfont.woff2') format('woff2'),
         url('../fonts/plume-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@import "icons";