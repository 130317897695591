/* Event Details Page */
.uwg-subheader{
    h2 {
        font-size: rem(25);
        font-weight: bold;
        line-height: 1;
        margin: 0;

        @media #{$tablet} {
            font-size: rem(20);
        }
    }

    h3 {
        font-family: $font_main_roman;
        font-size: rem(25);
        font-weight: 600;

        @media #{$tablet} {
            font-size: rem(20);
            line-height: 1.1;
        }
    }
}

.event--detail__content {
    img.float-left {
        margin: 25px 20px 20px 0;

        @media #{$mobile_mini} {
            width: 250px;
            max-height: 180px;
            object-fit: cover;
            object-position: top;
        }

        @media #{$mobile_sm} {
            float: none;
            margin-right: 0;
            padding: 0 20px;
            width: 100%;
        }
    }
}

.full-width-image {
    margin: 30px 0;

    @media #{$mobile} {
        margin-top: 15px;
    }
}

.uwg-contact-info {
    font-size: rem(16);
    font-family: $font_main_roman;
    padding-bottom: 20px;

    a {
        border-bottom: 1px solid $blue;
        text-decoration: none;

        &:hover {
            border-bottom: 1px solid transparent;
            color: $blue;
        }
    }
}

/* Listing page */
.datepicker-wrap  {
    display: inline-block;
    vertical-align: top;
    position: relative;

    #datepicker {
        position: absolute;
        z-index: 10;
    }

    .datepicker_date {
        padding: 0;
        width: 55px;
        color: $gray__xd;
        border: none;
        font-size: rem(15);
        background: transparent;
        font-weight: 500;
        margin-left: 20px;
    }

    .btn-close-datepicker {
        color: $gray__xd;
        display: inline-block;
        padding: 4px;
        cursor: pointer;
        font-size: rem(13);

        &:hover {
            color: $blue;
        }
    }
}


.view-type {
    font-size: rem(18);
    font-weight: 600;
    margin-bottom: 30px;

    a {
        display: inline-block;
        margin-left: 20px;
        color: $gray__xd;

        &:hover,
        &.active {
            color: $red;
        }

        span {
            padding-right: 15px;
        }
    }
}

.listing-view-wrapper .no-result {
    padding: 10px 25px;
}

.listing-item {
    border: 1px solid $gray__xl;
    margin-bottom: 20px;

    figure {
        .list & {
            margin-right: 25px;
            margin-bottom: 0;

            @media #{$tablet_lg} {
                margin-right: 20px;
            }

            @media #{$tablet} {
                margin-right: 10px;
            }

            @media #{$mobile} {
                margin-right: 0;
            }

            img {
                width: 100%;
                height: 220px;
            }
        }

        img {
            width: 100%;
            height: 260px;
        }
    }

    .listing-content {
        padding: 40px 20px 20px;

        @media #{$tablet_lg} {
            padding: 30px 20px 10px;
        }

        @media #{$tablet} {
            padding: 30px 20px 6px;
        }

        @media #{$mobile} {
            padding: 25px 20px 10px;
        }

        .grid & {
            padding: 20px 20px 10px;
        }
    }

    .date {
        font-size: rem(30);
        font-family: $font_main_bdCon;
        color: $red;
        line-height: 0.8;
        letter-spacing: -1px;
        margin-bottom: 8px;
        display: block;

        @media #{$tablet} {
            font-size: rem(23);
        }
    }

    h3 {
        font-size: rem(25);
        font-weight: bold;

        @media #{$tablet_lg} {
            font-size: rem(23);
        }

        @media #{$tablet} {
            font-size: rem(20);
        }

        a {
            display: inline-block;

            &:hover {
                color: $blue;
                text-decoration: underline;
            }
        }
    }

    p {
        font-size: rem(16);
        font-family: $font_main_roman;
        margin: 10px 0;

        @media #{$tablet_lg} {
            font-size: rem(15);
        }

        @media #{$tablet} {
            font-size: rem(14);
        }

        @media #{$mobile_sm_max} {
            display: none;
        }
    }

    .listing-date {
        font-size: rem(14);
        font-family: $font_main_roman;

        @media #{$tablet} {
            font-size: rem(12);
        }
    }

    .listing__category {
        padding-top: 70px;

        @media #{$tablet_lg} {
            padding-top: 60px;
        }

        @media #{$tablet} {
            padding-top: 55px;
        }

        @media #{$mobile_sm_max}{
            padding: 0 20px 30px;
        }

        .grid & {
            padding: 0 20px 30px;
        }
    }

    .category-box {
        color: $gray__xd;
        font-size: rem(14);
        font-family: $font_main_roman;
        display: inline-block;
        width: 100%;

        @media #{$tablet} {
            font-size: rem(12);
        }

        strong {
            font-weight: bold;
        }

        span {
            margin: 0 6px 0 0;
        }
    }
}

.no-js #loadMoreNews {
    display: none;
}