@import "fonts/fonts";
@import "variables-site/variables-site";
@import "layout/layout";


/* Custom Class */
.container {
    @media #{$tablet_lg} {
        width: 100%;
        max-width: 100%;
    }

    @media #{$desktop_min} {
        max-width: 1180px;
    }
}

.home {
    .main-content > .container--alert {
        padding-top: 55px;

        @media #{$tablet} {
            padding-top: 0;
        }
    }
}

.main-content,
.header,
.footer {
    @media #{$mobile_xsm} {
        min-width: 300px;
    }
}

.bg-white {
    background-color: $white;
    color: $gray__xd;
    border: 1px solid $gray__xl;
}

.bg-oil {
    background-color: $oil;
    color: $white;
}

.bg-cool-gray {
    background-color: $cool-gray;
    color: $white;
}

.bg-gray {
    background-color: $gray__xl;

    a {
        &.btn__white {
            color: $cool-gray;
            border-color: $cool-gray;
        }
    }
}

.bg-gray-l {
    background-color: $gray__l;
}

.bg-gray-xxl {
    background-color: $gray__xxl;
}

.bg-gray-xd {
    background-color: $gray__xd;
    color: $white;
}

.bg-primary {
    background-color: $red !important;
    color: $white;

     &:hover, &:focus, &:visited {
        background-color: $red !important;
    }

    .accordion & {
        .card-body {
            border-color: $red !important;
        }
    }

    a {
        &.btn__white {

            &:hover, &:focus {
                color: $red;
            }
        }
    }
}

.bg-secondary {
    background-color: $blue !important;
    color: $white;

    .accordion & {
        .card-body {
            border-color: $blue !important;
        }
    }

    a {
        &.btn__white {

            &:hover, &:focus {
                color: $blue;
            }
        }
    }
}

.bg-primary,
.bg-secondary {

    span {
        &.btn {

            &:hover, &:focus, &:focus-within {
                background: $white;
                color: $cool-gray;
                text-decoration: underline;

                & a, & a:hover, & a:focus {
                    background: transparent;
                    background-color: transparent;
                }
            }

            a, a:visited {
                &:hover, &:focus {
                    background: $white;
                    color: $cool-gray;
                    text-decoration: underline;
                }
            }

            &.btn__red {
                border-color: $white;
                background: $red;
                color: $white;

                &:hover, &:focus, &:focus-within {
                    border-color: $red;
                    background: $white;
                    color: $red;

                    & a, & a:hover, & a:focus {
                        color:$red;
                    }
                }
            }
        }
    }

    p {
        a, a:visited {
            &:not(.btn) {
                &:hover, &:focus {
                    color: $white;
                }
            }
        }
    }


    a, a:visited {
        color: $white;
        text-decoration: none;

        &:not(.btn):not(.carousel-control-next):not(.carousel-control-prev):not(.playpause_btn) {
            border-bottom: 1px solid;
            transition: color 0.5s ease;

            &:hover, &:focus {
                border-bottom: 1px solid transparent;
            }
        }

        &.btn {
            &:hover, &:focus {
                background: $white;
                color: $cool-gray;
                text-decoration: underline;
            }
        }

        &:hover, &:focus {
            color: $white;
            text-decoration: underline;
        }

        &:before {
            color: $white !important;
        }

        &.btn__red {
            border-color: $white;
            background: $red;
            color: $white;

            &:hover, &:focus {
                border-color: $red;
                background: $white;
                color: $red;
            }
        }
    }

    .uwg-social {
        a {
            &:hover,
            &:focus,
            &:active {
                opacity: .8;
            }
        }
    }
}

span {
    &.btn {

        a {
            background-color: transparent !important;
        }

        &:hover, &:focus, &:focus-within {
            text-decoration: underline;

            & a, & a:hover, & a:focus {
                background-color: transparent;
            }
        }
    }
}

.color-box {
    height: 200px;
    padding: 20px;
}

/* Fonts */
.font__roman {
    font-family: $font_main_roman
}
.font__medium {
    font-weight: 500;
}
.font__bold {
    font-weight: 700;
}
.font--con__thin {
    font-weight: 200;
    font-family: 'HelveticaNeueLTStdConThin';
}
.font--con__light {
    font-weight: 300;
    font-family: 'HelveticaNeueLTStdConLight';
}
.font--con{
    font-family: $font_main_con;
}
.font--con__bold{
    font-family: $font_main_bdCon;
}
.font--con__black{
    font-family: $font__helveticaBlkCon;
}

.font__heading {
    font-family: $font__heading;
    font-weight: normal;
}

.font__plume {
    font-family: 'plumeplume';
    font-weight: normal;
}

.main-content {
    margin-top: 142px;

    .home & {
        margin-top: 155px;

        @media #{$mobile} {
            margin-top: 68px;
        }
    }

    @media #{$mobile} {
        margin-top: 68px;
    }
}

body {
    &.nav-active {
        overflow: hidden;
        position: fixed;
        width: 100%;
    }
}

p {
    font-size: rem(20);
    font-family: $font_main_roman;

    @media #{$tablet} {
        font-size: rem(16);
    }

    a:not(.btn) {
        text-decoration: none;
        border-bottom: 2px solid;
        transition: color 0.5s ease;

        @media #{$tablet} {
            border-bottom: 1px solid;
        }

        &:hover {
            text-decoration: underline;
            border-bottom: 2px solid transparent;

            @media #{$tablet} {
                border-bottom: 1px solid transparent;
            }
        }
    }
}

ol {
    margin: 10px 0;
    padding-left: 0;

    li {
        font-size: rem(20);
        font-family: $font_main_roman;
        line-height: 1.3;
        margin: 10px 0;

        @media #{$tablet} {
            font-size: rem(16);
            line-height: 1.5;
        }
    }

    ul {
        padding-left: 10px;
    }
}

ol, ul {

    li {

        & > a {
            text-decoration: underline;
        }
    }

    .main-content & {
        li {

            & > a {
                &:not(.nav-link):not(.nav__link):not(.btn) {
                text-decoration: none;
                border-bottom: 2px solid;
                transition: color 0.5s ease;

                    @media #{$tablet} {
                        border-bottom: 1px solid;
                    }

                    &:hover, &:focus {
                        text-decoration: underline;
                        border-bottom: 2px solid transparent;

                        @media #{$tablet} {
                            border-bottom: 1px solid transparent;
                        }

                    }
                }
            }
        }
    }
}

.list-none {
    li {
        font-size: rem(18) !important;
        font-family: $font_main_roman;
        line-height: 1 !important;
        margin-top: 20px !important;
        margin-bottom: 0 !important;
        padding: 0 !important;

        &:before {
            content: "" !important;
        }

        @media #{$tablet} {
            font-size: rem(15) !important;
        }

        a {
            display: inline-block;
        }
    }
}

iframe {
    border: none;
}

a:focus,
:focus,
button:focus,
a span:focus {
    outline-color: #00a5e3;
    outline-style: solid;
    outline-width: 3px;
    transition: none;

    @media #{$mobile} {
        outline-width: 1px;
    }
}

/* Form Style */
form {
    h2 {
        font-size: rem(28);
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 30px;
    }

    .form-footer {
        text-align: right;
        padding: 15px 0;

        a {
            margin-right: 10px;
        }
    }
    .progress {
        height: 10px;
    }

    .progress-bar {
        .arrow {
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid $red;
            position: absolute;
            right: -5px;
            top: -5px;
        }
    }
}

.form-group {
    position: relative;
    padding-bottom: 10px;
}

.form-group {
    .form-control-label {
        color: $gray__xd;
        font-size: 1.5rem;
        font-family: $font_main_roman;

        @media #{$mobile} {
            font-size: 1.3rem;
        }
    }

    .form-control-placeholder {
        color: $gray__xd;
        font-size: rem(20);
        font-family: $font_main_roman;
        cursor: text;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(12px);
        transform-origin: 0% 100%;
        transition: transform .2s ease-out;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @media #{$mobile} {
            font-size: rem(16);
        }

        &.active {
            color: $gray__xd;
            font-size: rem(16);
            font-family: $font__main;
            font-weight: bold;
        }

        &.stay {
            transform: translateY(-14px) scale(0.8);
            transform-origin: 0 0;
            font-size: rem(15);
            font-weight: bold;
            font-family: $font__main;
        }
    }

    input:not([type="submit"]):not([type="button"]),
    textarea {
        background: transparent;
        border-radius: 0;
        font-family: $font__main;
        color: $gray__xd;

        @media #{$mobile} {
            font-size: rem(16);
        }

        &:focus {
            border-bottom-color: $blue;
            box-shadow: 0 1px 0 0 $blue;

            + .form-control-placeholder {
                transform: translateY(-14px) scale(0.8);
                transform-origin: 0 0;
                font-size: rem(15);
                font-weight: bold;
                font-family: $font__main;
            }
        }
    }

    select {
        background: transparent url(../images/select-arrow.png) no-repeat right center;
        border-radius: 0;
        font-family: $font_main_roman;
        color: $gray__xd;
        -webkit-appearance: none;
        appearance: none;
        -moz-appearance: none;

        @media #{$mobile} {
            font-size: rem(16);
        }
    }
    textarea {
        resize: vertical;
        padding: 10px;

        &:focus + .form-control-placeholder {
            background: $white;
            padding: 5px 10px;
        }
    }

    &.form-textarea .form-control-placeholder {
        left: 10px;
        width: auto;

        &.stay {
            background: $white;
            padding: 5px 10px;
        }
    }

    .character-counter {
        float: right;
        font-size: rem(12);
    }
}

.dropdown-content {
    li {
        margin: 0;

        &:before {
            content: "";
        }

        > a, > span {
            color: $gray__xd;
        }
    }
}

.form-check {
    padding-left: 0;

    [type="checkbox"] {
        position: absolute;
        opacity: 0;

        & + label {
            position: relative;
            cursor: pointer;
            padding-left: 35px;
            color: $gray__xd;
            font-size: rem(20);
            font-family: $font_main_roman;
            vertical-align: top;
            display: inline-block;
            @media #{$mobile} {
                font-size: rem(16);
            }
        }

        & + label:before {
            content: '';
            margin-right: 20px;
            display: inline-block;
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            border: 2px solid $gray__xd;
            position: absolute;
            top: 5px;
            left: 0;

            @media #{$mobile} {
                width: 18px;
                height: 18px;
            }
        }

        &:hover + label:before, &:focus + label:before {
            background: $trueblack__xl;
        }

        &:checked + label:before {
            border-color: $gray__xd;
            background: $gray__xd;
        }

        &:disabled + label {
            color: #b8b8b8;
            cursor: auto;
        }

        &:disabled + label:before {
            box-shadow: none;
            background: $trueblack__xl;
        }

        &:checked + label:after {
            content: "\e90c";
            font-family: 'uwg-icon';
            position: absolute;
            left: 4px;
            top: 6px;
            color: $white;
            font-size: rem(12);
            font-weight: bold;

            @media #{$mobile} {
                left: 3px;
                font-size: rem(11);
            }
        }
    }
}

.form-radio {
    input[type="radio"] {
        position: absolute;
        opacity: 0;

        & + label {
            position: relative;
            cursor: pointer;
            padding-left: 35px;
            color: $gray__xd;
            font-size: rem(20);
            font-family: $font_main_roman;
            vertical-align: top;
            display: inline-block;
            margin: 0;

            @media #{$mobile} {
                font-size: rem(16);
            }
        }

        & + label:before {
            content: '';
            margin-right: 20px;
            display: inline-block;
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            border: 2px solid $gray__xd;
            position: absolute;
            border-radius: 50%;
            top: 5px;
            left: 0;

            @media #{$mobile} {
                width: 18px;
                height: 18px;
            }
        }

        &:hover + label:before {
            background: $trueblack__xl;
        }

        &:focus + label:before,
        &:checked + label:before {
            border-color: $gray__xd;
            background: $gray__xd;
        }

        &:disabled + label {
            color: #b8b8b8;
            cursor: auto;
        }

        &:disabled + label:before {
            box-shadow: none;
            background: $trueblack__xl;
        }
    }
}


/* Nav Bar */
nav {
    background: transparent;
    height: auto;
    box-shadow: none;

    a {
        &:hover, &:focus {
            text-decoration: underline;
        }
    }
}

/* Banner Section */
.banner__left-col {
    img {
        width: 100%;

        @media #{$mobile} {
            object-fit: cover;
            object-position: right;
        }
    }

    .jumbotron {
        height: 100%;
    }

    @media #{$tablet_mini} {
        max-width: 100%;
        flex-grow: 1;
        flex: 100%;
    }

    .video-container {
        width: 100%;
        height: 100%;

        .video-js {
            height: 100%;
            width: 100%;

            &.vjs-paused .vjs-control-bar {
                opacity: 0;
            }

        }

        @media #{$tablet} {
            height: 558px;
        }

        @media #{$mobile} {
            height: auto;
        }
    }
}

.banner__right-col {
    background-color: $gray__xl;
    //border-top: 1px solid $gray__xl;
    padding-bottom: 30px;

    @media #{$tablet_mini} {
        max-width: 100%;
        flex-grow: 1;
        flex: 100%;

        .col-lg-12 {
            max-width: 50%;
        }

        .bg-white, .bg-secondary {
            display: flex;
            align-items: center;
        }
    }

    @media #{$tablet_only} {
        padding-bottom: 0;
        border-top: none;
        border-bottom: 1px solid $gray__xl;

        .bg-white, .bg-secondary {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .bg-gray {
            padding-bottom: 30px;
        }
    }

    @media #{$mobile_sm_only} {
        .bg-white, .bg-secondary {
            display: flex;
            align-items: center;
        }
    }

    @media #{$mobile}{
        padding-bottom: 0;
        .bg-gray {
            padding-bottom: 30px;
        }
    }

    img {
        width: 100%;
    }
}

.banner__right-top {
    padding: 30px 40px;
    text-align: right;

    @media #{$desktop_sm_only} {
        padding: 30px 20px;
    }

    @media #{$mobile} {
        padding: 20px;
    }

    .container-small & {
        padding: 20px;
    }

    h2 {
        font-size: rem(34);
        font-weight: bold;
        color: $red;
        line-height: 1.06;
        margin: 0 0 30px;
        text-align: left;

        .bg-primary & {
            color: $white;
        }

        @media #{$tablet_only} {
            font-size: rem(25);
            margin-bottom: 20px;
        }

        @media #{$mobile} {
            font-size: rem(22);
            margin-bottom: 20px;
        }

        .container-small & {
            font-size: rem(22);
            margin-bottom: 20px;
        }
    }

    .read-more {
        color: $gray__xd;
        font-weight: bold;

        &:hover,
        &:focus {
            color: $blue;
        }

        .bg-primary & {
            color: $white;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        @media #{$mobile} {
            font-size: rem(14);
        }

        .container-small & {
            font-size: rem(14);
        }
    }

    .bg-secondary & {
        h2 {
            color: $white;
        }

        .read-more {
            color: $white;

            &:hover,
            &:focus {
                color: $white;
                text-decoration: underline;
            }
        }
    }
}

.carousel__image-content {
    width: 100%;

    img {
        height: 297px;
        width: auto;
        max-width: 600px;

        @media #{$mobile} {
            width: 100%;
            height: auto;
        }

        .container-small & {
            width: 100%;
            height: auto;
        }
    }

    @media #{$mobile} {
        height: 250px;
        overflow: hidden;
    }

    .container-small & {
        height: 250px;
        overflow: hidden;
    }
}

.carousel__image {
    object-fit: cover;
}

.carousel__content {
    padding: 20px 40px 10px;
    min-height: 120px;

    @media #{$desktop_lg} {
        padding: 30px 30px 20px;
    }

    @media #{$desktop_sm_only} {
        padding: 30px 20px 20px;
        height: auto;
    }

    @media #{$mobile} {
        padding: 20px;
        height: auto;
    }

    .container-small & {
        padding: 20px;
    }

    h3 {
        color: $gray__xd;
        font-size: rem(28);
        font-weight: 500;

        @media #{$desktop_xlg} {
            font-size: rem(25);
            min-height: 90px;
        }

        @media #{$tablet_lg} {
            font-size: rem(25);
            min-height: 108px;
        }

        @media #{$tablet_only} {
            font-size: rem(21);
            min-height: 50px;
        }

        @media #{$mobile} {
            font-size: rem(20);
            min-height: 50px;
        }

        @media #{$mobile_max} {
            min-height: 50px;
        }

        .container-small & {
            font-size: rem(20);
            min-height: 50px;
        }

        a {
            color: $gray__xd;
            text-decoration: none;

            &:hover, &:focus {
                color: $red;
                text-decoration: underline;
            }
        }
    }
}


.masonry-blocks-section {
    padding: 0;
    background-color: $white;

    @media #{$mobile} {
        padding-top: 40px;
    }

    .section-header {
        width: 350px;
        display: inline-block;
        vertical-align: middle;
        letter-spacing: -1px;

        @media #{$tablet} {
            width: 100%;
            display: block;
        }
    }

    .masonry-tabs {
        @media #{$desktop} {
            padding-left: 350px;
            margin-top: -90px;
        }
    }

    .masonry-gallery {
        @media #{$mobile_sm_max} {
            position: relative;
            height: auto !important;
        }
    }

    .grid-item {
        @media #{$mobile_sm_max} {
            position: relative !important;
            left: 0 !important;
            top: 0 !important;
            width: 0 !important;
            max-width: 100% !important;
            flex: 0 0 100%;
            height: 0 !important;
            opacity: 0;
            margin-bottom: 0 !important;

            &.active,
            &:first-child {
                width: auto !important;
                height: auto !important;
                opacity: 1;
                margin-bottom: 20px !important
            }
        }
    }
}

.masonry-tabs {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    text-align: right;

    &.button-group .button {
        float: none;
        margin-left: 0;
        font-size: rem(14);
        font-family: $font_main_roman;
        display: inline-block;
        margin-right: 7px;
        color: $blue;
        cursor: pointer;
        @include transition(all 0.5s ease);

        &:last-child {
            margin-right: 0;
        }

        &.is-checked a {
            background-color: $red;
            color: $white;
            border-color: $red !important;
            position: relative;

            &:after {
                content: "\e90c";
                font-family: 'uwg-icon';
                color: $white;
                padding-left: 8px;
            }
        }

        input[type="checkbox"]{
            display: none;
        }

        @media #{$mobile} {
            margin-right: 5px;
            margin-bottom: 5px;
            display: none;
        }
    }

    @media #{$tablet} {
        text-align: left;
        margin-top: 10px;
    }

    @media #{$mobile} {
        text-align: left;
        border: 1px solid $gray__xl !important;
        border-radius: 18px;
        padding: 7px 20px;

        .filter-mob {
            font-size: rem(14);
            font-family: $font_main_roman;
            color: $blue;

            .fa {
                float: right;
                font-size: rem(20);
                color: $gray__xd;
            }
        }

        &.show {
            .button {
                display: inline-block;
            }

            .filter-mob {
                margin-bottom: 20px;

                .fa:before {
                    content: "\f106";
                }
            }
        }
    }

    .button a {
        border-radius: 0;
        border: 1px solid $gray__xl !important;
        font-size: rem(14);
        color: $blue;
        padding: 7px 20px;
        display: inline-block;
        position: relative;

        &:hover, &:focus {
            background-color: $gray__xl;
            color: $gray__xd;
            border-color: $gray__xl !important;
            text-decoration: underline;
        }

        /*&:focus {
            border-color: $red;
            background-color: $red;
            color: $white;
            text-decoration: none;
        } */
    }

    .active {
        border-color: $red;
        background-color: $red;
        color: $white;
        text-decoration: none;
    }
}


.masonry-gallery {
    margin-top: 20px;
    padding: 0;

    &.row {
        margin-right: -10px;
        margin-left: -10px;
    }

    .grid-item {
        padding: 0 10px;
        margin-bottom: 20px;

        @media #{$tablet_sm_only} {
            &.last {
                display: none;
            }
        }
    }

    figure {
        margin: 0;
        overflow: hidden;
        position: relative;
    }
}

.image-pattern-top {
    position: absolute;
    overflow: hidden;
    background: url(../images/paintbrush-effect2.png) no-repeat center center;
    top: -9px;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 30px;
}

.image-pattern-bottom {
    background: url(../images/paintbrush-effect.png) no-repeat center center;
    width: 100%;
    height: 59px;
    position: absolute;
    bottom: -25px;
    z-index: 10;
    left: 0;
}

.masonry-gallery__image {
    width: 100%;
    object-fit: cover;
    max-height: 370px;
}

.masonry-gallery__content {
    border: 1px solid $gray__xl;
    padding: 25px 20px 30px;

    h3 {
        font-size: rem(25);
        font-weight: bold;
        color: $blue;
        line-height: 1.2;

        a {
            color: $blue;
            display: inline-block;
            cursor: pointer;

            &:hover, &:focus, &:visited{
                text-decoration: underline;
            }

            &:visited {
                color: $gray__xd;
                text-decoration: underline;
            }
        }

        @media #{$mobile} {
            font-size: rem(19);
        }
    }

    &.bg-box {
        padding: 20px 25px 30px;

        h3 {
            font-size: rem(38);
            font-family: $font_main_bdCon;
            font-weight: normal;
            color: $gray__xd;
            letter-spacing: -1px;
            line-height: 1.1;
            text-transform: uppercase;
            margin: 0 0 10px;
            word-break: break-word;
            word-wrap: break-word;

            @media #{$tablet_max} {
                font-size: rem(28);
            }

            @media #{$mobile} {
                font-size: rem(25);
            }

            @media all and (-ms-high-contrast:none) {
                font-size: rem(35);
                letter-spacing: -2px;

                @media #{$tablet_lg} {
                    font-size: rem(30);
                }

                @media #{$mobile} {
                    font-size: rem(25);
                }
            }
        }
        _:-ms-lang(x), _:-webkit-full-screen, h3 {
            font-size: rem(35);
            letter-spacing: -2px;

            @media #{$tablet_lg} {
                font-size: rem(30);
            }

            @media #{$mobile} {
                font-size: rem(25);
            }
        }

        &.bg-gray {
            color: $gray__xd;

            a {
                color: $gray__xd;

                &:visited, &.selected {
                    color: $gray__xd;
                    text-decoration: underline;
                }
            }
        }

        &.bg-primary {
            color: $white;

            a {
                color: $white;

                &:visited, &.selected {
                    text-decoration: underline;
                }
            }
        }
    }

    .date-box {
        font-size: rem(30);
        font-family: $font_main_bdCon;
        color: $red;
        line-height: 1;
        letter-spacing: -1px;
        margin-bottom: 8px;
        display: block;

        @media #{$mobile} {
            font-size: rem(23);
        }
    }

    p {
        font-size: rem(16);
        font-family: $font_main_roman;
        margin: 10px 0;

        @media #{$mobile} {
            font-size: rem(13);
            margin-bottom: 10px;
        }
    }

    .listing-date {
        font-size: rem(14);
        font-family: $font_main_roman;

        @media #{$mobile} {
            font-size: rem(12);
            margin-top: 8px;
        }
    }

    .category-box {
        color: $gray__xd;
        font-size: rem(14);
        font-family: $font_main_roman;
        display: inline-block;
        width: 100%;

        @media #{$mobile} {
            font-size: rem(12);
        }

        strong {
            font-weight: bold;
        }

        span {
            margin: 0 6px 0 0;
        }
    }
}

.hyphens {
    hyphens: auto;
}

.text-no-wrap {
    word-break: normal !important;
    word-wrap: normal !important;
}

.section-header {
    font-size: rem(36);
    font-weight: bold;
    color: $gray__xd;
    width: 100%;
    display: inline-block;
    letter-spacing: -1px;

    @media #{$tablet} {
        font-size: rem(30);
        line-height: 1;
    }

    @media #{$mobile} {
        font-size: rem(27);
        line-height: 1;
    }

    span {
        display: block;
        font-size: rem(14);
        line-height: 1.5;
        font-weight: 500;
        text-transform: uppercase;
        color: $red;
        letter-spacing: 3px;

        @media #{$mobile} {
            font-size: rem(11);
        }
    }
}

.embed-responsive-21by9::before {
    padding-top: 448px;

    @media #{$tablet_only} {
        padding-top: 289px;
    }
}

.two-full-width-image-section {
    background-color: $white;

    @media #{$mobile} {
        padding-bottom: 40px;
    }

    figure {
        margin: 0;
        position: relative;
        overflow: hidden;

        img {
            object-fit: cover;
            width: 100%;

            @media #{$tablet_only} {
                height: 305px;
            }

            @media #{$mobile} {
                height: 300px;
            }
        }
    }

    .video__btn {
        position: absolute;
        top: 50%;
        left: 50%;
        @include translate(-50%, -50%);
        z-index: 1;

        a {
            width: 58px;
            height: 58px;
            border: 2px solid $white;
            border-radius: 50%;
            display: block;
            text-align: center;
            color: $white;
            line-height: 3.5;
            padding-left: 5px;

            &:hover {
                color: $red;
                border-color: $red;
            }
        }
    }
}

.success__image {
    width: 100%;
    height: 529px;

    img {
        width: 940px;
        height: 529px;
    }
}

.section-with-images__left-col {
    width: 50%;
    float: left;
    padding: 50px 12px 0 0;
    position: relative;
    z-index: 10;

    @media #{$mobile} {
        padding: 20px 0 0;
    }

    .padding-left {
        padding-top: 30px;
        float: right;
        width: 390px;
        margin-right: 153px;
        text-align: left;

        @media #{$tablet_lg} {
            float: left;
            margin-right: 0;
            padding-left: 15px;
        }

        @media #{$tablet} {
            padding: 30px 15px;
            width: 90%;
        }
    }

    @media #{$mobile} {
        width: 100%;
    }

    figure img {
        height: 528px;

        @media #{$tablet} {
            height: 300px;
        }

        @media #{$mobile} {
            height: 300px;
        }
    }
}


.section-with-images__right-col {
    width: 50%;
    float: left;
    padding-left: 12px;
    position: relative;

    @media #{$mobile} {
        padding: 0;
    }

    .padding-left {
        padding-top: 30px;
        float: left;
        width: 390px;
        margin-right: 153px;
        margin-left: 3rem;
        text-align: left;

        @media #{$tablet_lg} {
            float: left;
            margin-right: 0;
            margin-left: 0;
            padding-left: 15px;
        }

        @media #{$tablet} {
            padding: 30px 15px;
            width: 90%;
        }

        @media #{$mobile} {
            padding: 30px 20px;
        }
    }

    @media #{$mobile} {
        width: 100%;
    }

    figure img {
        height: 528px;

        @media #{$tablet} {
            height: 300px;
        }

        @media #{$mobile} {
            height: 300px;
        }
    }
}

.video-embed {
    .image-container {
        width: 100%;
        height: 450px;
        position: absolute;
        z-index: 1;

        @media #{$tablet} {
            height: 300px;
        }

        @media #{$mobile} {
            height: 300px;
        }
    }
    @media #{$mobile} {
        height: 300px;

        .embed-responsive {
            height: 100%;
        }
    }
}

.section-with-images__content {
    h2 {
        font-size: rem(55);
        font-family: $font_main_bdCon;
        color: $blue;
        margin: 0 0 5px;
        letter-spacing: -1px;
        text-transform: uppercase;

        @media #{$tablet} {
            font-size: rem(42);
        }

        @media #{$mobile} {
            font-size: rem(40);
        }
    }

    p {
        font-size: rem(20);
        font-family: $font_main_roman;
        color: $gray__xd;
        max-width: 400px;
        margin-bottom: 25px;

        @media #{$mobile} {
            font-size: rem(15);
            line-height: 20px;
            max-width: 90%;
        }
    }

    a {
        color: $blue;
        font-size: rem(16);
        font-weight: bold;

        &:hover {
            color: $gray__l;
            text-decoration: none;
        }

        @media #{$mobile} {
            font-size: rem(13);
            line-height: 20px;
        }
    }
}

.modal-full.modal-dialog {
    max-width: 90%;

    .close {
        right: -50px;
        top: -30px;

        &:focus {
            outline: 0;
        }
    }

    @media #{$mobile} {
        max-width: 80%;
        margin: 20px auto;

        .close {
            right: -40px;
        }
    }
}


.event-highlights-section {
    background-color: $white;

    .masonry-content {
        small.date-bottom {
            margin-top: 0;
        }
    }
}

.events__image {
    width: 100%;
    height: 262px;
}

.admission-news__image {
    width: 100%;
    height: 200px;
}

.divider-pattern {
    background: url(../images/paintbrush-effect-vertical.png) center bottom no-repeat;
    height: 100%;
    display: block;
    width: 57px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: 20;

    @media #{$mobile} {
        background-size: 40px 100%;
    }
}

.counter-wrap {
    list-style: none;
    margin: 50px 0 0 0;
    padding: 0;
    position: relative;
    float: left;
    width: 100%;

    @media #{$mobile} {
        margin-top: 20px;
        padding: 0 0px;
    }

    .top-space {
        margin-top: 3px;
    }

    .counter-box {
        color: $white;
        padding: 31px 35px 31px 38px;
        display: block;
        height: 100%;

        @media #{$tablet} {
            padding: 30px 32px;
        }

        @media #{$mobile} {
            padding: 25px 30px;
        }

        & a {

            &:hover, &:focus, &:visited, &.selected {
                text-decoration: underline;
                color: $white;
            }

            &.bg-primary, &.bg-secondary {
                &:hover, &:focus {
                    background: $gray__l !important;
                }
            }
        }

    }

    .number {
        font-size: rem(70);
        font-family: $font_main_hvCon;
        color: $white;
        line-height: 1;
        margin-bottom: 8px;

        @media #{$desktop_sm} {
            font-size: rem(50);
        }

        @media #{$tablet} {
            font-size: rem(45);
        }

        @media #{$mobile} {
            font-size: rem(38);
        }
    }

    h3 {
        font-size: rem(22);
        font-family: $font_main_roman;
        color: $white;
        margin: 0;

        @media #{$tablet} {
            font-size: rem(18);
        }

        @media #{$mobile} {
            font-size: rem(15);
        }
    }
}

.uwg-facts-section {
    padding: 80px 30px 20px;
    background-color: $white;

    @media #{$tablet_lg} {
        padding: 60px 15px 10px;
    }

    @media #{$mobile} {
        padding: 50px 0 10px;
    }

    .section-header {
        span {
            font-size: rem(11);
        }
    }

    .right-warp {
        padding: 50px 0 0 40px;

        @media #{$desktop_xlg} {
            padding-left: 20px;
        }

        @media #{$mobile} {
            padding: 30px 10px 0;
        }

        h2 {
            font-size: rem(55);
            font-family: $font_main_bdCon;
            line-height: 1;
            letter-spacing: -1px;
            color: $gray__xd;
            margin: 0 0 30px;
            text-transform: uppercase;

            @media #{$tablet} {
                font-size: rem(3)x;
            }

            @media #{$mobile} {
                font-size: rem(37);
            }
        }

        p {
            font-size: rem(20);
            font-family: $font_main_roman;
            color: $gray__xd;

            @media #{$mobile} {
                font-size: rem(15);
                line-height: 22px;
            }
        }
    }
}

.see-more-btn-wrap {
    text-align: right;

    &.bt-position {
        position: relative;
        right: 0;
        bottom: 70px;


        @media #{$tablet} {
            bottom: 0;
        }

        @media #{$mobile} {
            bottom: 0;
        }

        &.active {
            bottom: 0;
        }
    }

    @media #{$mobile} {
        margin-bottom: 30px;
    }
}

.see-more-success {
    bottom: 50px;
    right: 0;
    position: relative;
    text-align: right;
    clear: both;

    @media #{$mobile} {
        bottom: 0;
    }
}

.image-container {

    &.parallax-slider {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        @media #{$tablet} {
            background-size: cover;
        }
    }

    &.compat-object-fit {
        background-size: cover;
        background-position: top center;

        img {
            opacity: 0;
        }
    }

    img {
       object-fit: cover;

       .no-js & {
            display: block !important;
        }
    }

}

.iphoneDevice .parallax-slider,  .androidDevice .parallax-slider {
    background-size: cover;
}

#uwg_loader {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $gray__xd;

    .no-js & {
       z-index: 0;
       animation: fadeOut 3s forwards;
    }
}

.signal {
    border: 3px solid $white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    opacity: 0;
    position: absolute;
    animation: pulsate 1.25s ease-out;
    animation-iteration-count: infinite;

    .no-js & {
        animation: pulsate 1.25s ease-out;
        animation-iteration-count: infinite;
    }
}

@keyframes fadeOut {
    0% { opacity: 1; z-index: 9999;}
    90% { opacity: 0.9;}
    100% { opacity: 0; z-index: -1;}
}

@keyframes pulsate {
    0% {
      transform: scale(.1);
      opacity: 0.0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
}

.no-js .lazy,
.no-js .lazy-bg {
    display: none;
}