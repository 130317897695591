/* A To Z Search page */
.main-content.az-index {
    @media #{$tablet_min} {
        margin-top: 200px;
    }
}

.search-wrapper {
    padding: 15px 0;

    @media #{$mobile} {
        padding: 0;
    }
}

.search__sidebar {
    padding-right: 50px;

    @media #{$tablet} {
        padding-right: 30px;
    }

    @media #{$mobile} {
        padding-right: 0;
    }
}

.uwg-search__form-wrap {
    position: relative;
    padding-bottom: 20px;

    @media #{$mobile} {
        padding-bottom: 0;
    }

    input {
        border-radius: 0;
        border: 2px solid $gray__xd;
        font-size: rem(18);
        font-family: $font_main_roman;
        color: $gray__xd;
        padding: 10px 55px 10px 20px;
        width: 100%;
        line-height: 1.5;
        display: block;
        height: 50px;

        @media #{$tablet} {
            font-size: rem(15);
            padding: 7px 50px 7px 20px;
            height: 40px;
        }

        &:focus {
            border-color: $blue;
        }

        &::-webkit-input-placeholder {
            color: $gray__xd;
            opacity: 1;
        }

        &::-moz-placeholder {
            color: $gray__xd;
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: $gray__xd;
            opacity: 1;
        }
        &:-moz-placeholder {
            color: $gray__xd;
            opacity: 1;
        }
    }

    .search__submit {
        background: transparent;
        position: absolute;
        right: 0;
        top: 0;
        border: none;
        font-size: rem(24);
        color: $gray__xd;
        width: 52px;
        height: 51px;
        padding: 3px 8px 0 0;
        line-height: 1;
        z-index: 10;
        cursor: pointer;

        @media #{$tablet} {
            font-size: rem(20);
            height: 40px;
            width: 47px;
        }

        &:focus,
        &:hover {
            color: $blue;
        }

        &:focus {
            outline: 3px solid #00a5e3;
        }
    }
}

.search__sidebar-inner {
    @media #{$mobile} {
        border: 1px solid $gray__xl;
        margin: 15px 0;
        border-radius: 20px;

        h2 {
            font-size: rem(15);
            color: $blue;
            padding: 7px 20px;
            margin: 0;
            cursor: pointer;

            span {
                float: right;
                color: $gray__xd;
                font-size: rem(20);
                line-height: 18px;
            }

            &.open span:before {
                content: "\f106";
            }

            &:hover {
                color: $gray__xd;
            }
        }
    }
}

.search__filter-wrap {
    padding: 20px 0;
    border-bottom: 1px solid $gray__xl;
    margin-bottom: 30px;
    display: none;

    h2 {
        font-size: rem(25);
        font-weight: bold;
        margin-bottom: 18px;
    }

    @media #{$mobile} {
        padding: 10px 20px;

        &.show {
            display: block;
        }
    }

}

.search__filter-list {
    li {
        display: inline-block;
        font-size: rem(14);
        font-family: $font_main_roman;
        margin-right: 12px;
        margin-bottom: 5px;

        @media #{$mobile} {
            font-size: rem(12);
        }

        a {
            color: $gray__xd;

            .icon-close {
                font-size: rem(11);
                font-weight: 900;
                padding-left: 5px;
            }

            &:hover,
            &:focus {
                color: $red;
            }
        }
    }
}

.search__category_course-widget,
.search__category-widget {
    margin-top: 20px;

    h3 {
        font-size: rem(20);
        font-weight: bold;
        margin-bottom: 15px;

        @media #{$mobile} {
            font-size: rem(16);
        }
    }

    @media #{$mobile} {
        display: none;

        &.show {
            display: block;
            padding: 0 20px;
        }

        &:last-child {
            margin-bottom: 20px;
        }
    }
}

.search__category-list {
    li {
        font-size: rem(14);
        font-family: $font_main_roman;
        padding: 0;
        margin: 0;

        &:before {
            display: none;
        }

        @media #{$mobile} {
            font-size: rem(12);
        }

        label {
            color: $blue;
            cursor: pointer;

            &:hover, &:focus {
                text-decoration: underline;
            }
        }
    }
}

.search__result_course-wrap,
.search__result-wrap {
    position: relative;
    width: 100%;
    padding: 0 30px;

    @media #{$tablet} {
        padding: 0 20px;
    }

    @media #{$mobile} {
        padding: 0 10px;
    }
}

.no-js-message {
    display: none;

    .no-js & {
        display: block;
        margin-top: 30px;
    }
}

.search-wrapper__inner {
    position: relative;
    display: flex;
    background: $gray__xxxl;
    padding: 30px 0;
    border: 1px solid #f1f1f1;

    @media #{$mobile} {
        overflow: hidden;
        width: 100%;
    }

    @media #{$tablet} {
        padding: 20px 0;
    }

    @media #{$mobile} {
        padding: 20px 0;
        width: 100%;
    }
}

.search-wrapper__inner-wrap {
    background: $gray__xxxl;
    margin-bottom: 30px;
    position: relative;
    width: 100%;

    .no-record {
        margin-top: 30px;
    }
}

.search__nav,
.search-course__nav {
    background: $gray__xxxl;
    width: 60px;
    z-index: 90;
    height: calc(100vh - 60px);
    right: 0;
    position: absolute;
    top: 0px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;

    @media #{$mobile_landscape} {
        height: calc(100vh - 5px);
    }

    .no-record & {
        display: none !important;
    }


    &.is_stuck {
        position: fixed;
        background: $gray__xxxl;
        right: 16px;
        top: 60px !important;

        &.unstick {
            right: 0
        }
    }

    li {
        &[id*='aznav-'],
        &[id^='aznav-'] {
            a {
                border-bottom: none !important;
            }
        }

        text-align: center;
        margin: 15px 0 !important;
        font-size: rem(15) !important;
        font-weight: bold;
        padding: 5px !important;
        text-transform: uppercase;

        &:before {
            content: "" !important;
        }
    }

    a {
        color: $blue;
        text-decoration: underline;
        display: block;
        padding: 5px 0;

        &:hover {
            color: $red;
            text-decoration: none;
        }

        &:visited {
            color: $gray__xd;
        }
    }
}

.search__nav.search__nav-horizontal {
    height: 50px;
    right: 0;
    left: 0;
    position: initial;
    top: 119px;

    li {
        margin: 4px 0 !important;
    }

    a {
        color: $white;
        text-decoration: none;

        &:hover {
            color: $white;
            text-decoration: underline;
        }

        &:visited {
            color: $white;
            text-decoration: none;
        }
    }
}

.search__result-item {
    margin-bottom: 22px;

    @media #{$mobile} {
        margin-bottom: 10px;
    }

    .search__character {
        color: $red;
        font-size: rem(46);
        font-weight: bold;
        letter-spacing: -1px;
        display: inline-block;
        width: 72px;
        vertical-align: top;
        line-height: 0.85;
        text-transform: uppercase;

        @media #{$tablet} {
            font-size: rem(40);
            width: 52px;
        }

        @media #{$mobile} {
            font-size: rem(36);
            display: block;
            margin-bottom: 15px;
        }
    }
}

.search__result-list {
    display: inline-block;
    width: calc(100% - 77px);

    @media #{$tablet} {
        width: calc(100% - 57px);
    }

    @media #{$mobile} {
        width: 100%;

        li {
            padding-right: 60px;
        }
    }

    li {
        border-bottom: 1px solid $trueblack__xl;
        padding-bottom: 18px;
        margin-bottom: 15px;
        padding-left: 0;

        &:before {
            content: "";
        }
    }

    .program-name {
        font-size: rem(20);
        font-family: $font__main;
        font-weight: bold;
        letter-spacing: 0;
        margin-bottom: 8px;

        @media #{$tablet} {
            font-size: rem(18);
        }

        @media #{$mobile} {
            font-size: rem(16);
            margin-bottom: 5px;
        }
    }

    a {
        color: $blue;
        border-bottom: 1px solid;
        text-decoration: none;
        transition: color 0.5s ease;

        &:hover,
        &:focus {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            border-bottom: 1px solid transparent;
        }

        &.icon {
            border: 0;
            font-size: rem(35);
        }
    }

    span {
        font-size: rem(16);
        font-family: $font_main_roman;
        display: inline;
        margin-right: 20px;

        @media #{$tablet} {
            font-size: rem(14);
        }

        @media #{$mobile} {
            font-size: rem(13);
            display: inline;
            margin-right: 15px;
        }
    }
}