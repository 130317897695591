.ssologout {
    margin-bottom: 20px;

    @media #{$tablet_min} {
        display: none;
    }
}

.modal-dialog {
    pointer-events: all;

    a {
        font-weight: 600;
    }
}

.boxed {
    height: auto !important;
    width: 100%;
}

.myuwg {

    .fa-exclamation-triangle {
        color: $gold;

        &.overdue {
            color: $red;
        }
    }

    .item-list {
        .fa-exclamation-triangle {
            font-size: rem(18);
        }
    }

    .fa-check-square {
        color: $blue;
        font-size: rem(22);
    }

    .fa-square {
        font-size: rem(22);
    }

    .fa-info-circle {
        font-size: rem(21);
    }

    .fa-uwg-wolfie-solid, .fa-uwg-wolfie, .fa-uwg-wolfie-outline  {
        font-size: rem(22);
        color: $cool-gray;
    }
}

.legend-container {
    width: 50%;

    a {
        text-decoration: underline;
    }

    .far, .fas, .fal, .fad, .fak {
        font-size: initial;
    }

    @media #{$mobile} {
        width: 100%;
    }

}

#myuwg-container {
    .item-list {
        li {
            a {
                border: 0;
                text-decoration: none;

                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
        }
    }

    li {
        a {
            font-weight: 600;
        }
    }
}

