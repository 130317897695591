/* Mega Menu */

.site-navigation {
    position: relative;
    width: 100%;    
    padding: 3px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
    height: calc(100vh - 175px);

    @media #{$tablet_min} {
        //height: calc(100vh - 330px);
        height:100%;
    }

    @media #{$mobile} {       
        //height: calc(100vh - 290px)
    }
    @media #{$mobile_landscape} {
        //.iphoneDevice & {height: calc(100vh - 250px);}

        //.androidDevice & {height: calc(100vh - 280px)}
    }

    a {
        position: relative;

        &[href='#'] {

            &.list__link {
                color: $cool-gray !important;
                cursor: default;

                &:hover, &:focus {
                    border-bottom: 2px solid transparent;
                    text-decoration: none;
                }
            }
        }

        &[href$='.pdf']:after {
            content: " (PDF)";
        }

        &.list__link {
            &:focus {
                border-bottom: 2px solid $blue;
                text-decoration: none;
            }
        }
    }

    .list__link {
        &:focus, &:visited, &.selected {
            border-bottom: 2px solid transparent;
        }

        &:hover {
            border-bottom: 2px solid $blue;
            text-decoration: none;
        }

        line-height: 33px;
        padding-bottom: .5em;

        @media #{$mobile} {
            line-height: 30px;
            padding-bottom: .25em;
        }
    }


    @media #{$mobile} {
        .list {
            height: 100vh !important;
            z-index: 999999999999;
        }
    }

    /*.list__link[href="#"] {
        font-weight: 100;
        text-decoration: none;
        transition: none;
        cursor: default;
        color: $cool-gray;

        &:focus, &:visited, &.selected {
            border-bottom: 2px solid transparent;
        }

        &:hover {
            border-bottom: 2px solid transparent;
            cursor: default;
        }
    }*/
}

.site-navigation__list {
    .iphoneDevice &, .androidDevice & {
        li {
            &.list__item:last-child {
                padding-bottom: 2.5rem;
            }
        }
    }
}

.site-navigation__sub-nav {
    background-color: #edeff0;
    left: 100vw;
    min-height: 100%;
    position: absolute;
    text-transform: none;
    top: 0;
    transition: left .5s ease-in-out;
    width: 100%;
    z-index: 10;
    padding: 3px;
    display: none;

    li {        

        a {
            color: $blue;
            font-size: rem(26);
            font-family: $font__main;
            font-weight: bold;
            padding: 3px 10px 3px 3px;
            border-bottom: 2px solid transparent;
            display: block;

            @media #{$tablet_lg} {
                font-size: rem(20);
            }

            .fa, .fas, .fal, .fad, .fak {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                font-size: rem(30);
                display: block;
                right: 0;
            }

            &:hover, &:visited, &.selected {
                border-bottom-color: $blue;
                color: $blue;
            }
        }
    }
}
.site-navigation__sub-nav.is-loaded{
    left: 0;
}
.site-navigation__sub-nav.is-active {
    display: block;

    @media #{$mobile} {
        padding-bottom: 30px;
    }
}

.list__link--toggle {
    border: none;
    background: none;
    color: $blue;
    font-size: rem(26);
    font-family: $font__main;
    font-weight: bold;
    width: 100%;
    text-align: left;

    @media #{$tablet_lg} {
        font-size: rem(20);
    }
}

.list__link {
    color: $blue;
    font-size: rem(26);
    font-family: $font__main;
    font-weight: bold;
    padding: 3px 10px 3px 3px;
    border-bottom: 2px solid transparent;
    display: block;
    position: relative;

    @media #{$tablet_lg} {
        font-size: rem(20);
    }

    .fa, .fas, .fal, .fad, .fak {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: rem(30);
        display: block;
        right: 0;
    }

    &:hover, &:focus, &:visited, &.selected {
        border-bottom-color: $blue;
        color: $blue !important;
    }
}

.site-navigation__back {
    border: none;
    background: none;
    color: $blue;
    font-size: rem(26);
    font-family: $font__main;
    font-weight: bold;
    padding: 3px 3px 3px 20px;
    border-bottom: 2px solid transparent;
    width: 100%;
    text-align: left;
    position: relative;

    @media #{$tablet_lg} {
        font-size: rem(20);
    }

    .fa, .fas, .fal, .fad, .fak {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: rem(30);
        display: block;        
        left: 0;
    }

    &:hover,
    &:focus, &:visited, &.selected {
        border-bottom: 2px solid transparent;
    }

    &:hover, &:focus {
        text-decoration: underline;
    }
}