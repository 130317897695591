@font-face {
  font-family: 'uwg-icon';
  src:  url('../fonts/uwg-icon.eot?7j50pu');
  src:  url('../fonts/uwg-icon.eot?7j50pu#iefix') format('embedded-opentype'),
    url('../fonts/uwg-icon.ttf?7j50pu') format('truetype'),
    url('../fonts/uwg-icon.woff?7j50pu') format('woff'),
    url('../fonts/uwg-icon.svg?7j50pu#uwg-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'uwg-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\e900";
}
.icon-giphy:before {
  content: "\e901";
}
.icon-instagram:before {
  content: "\e902";
}
.icon-linkedin:before {
  content: "\e903";
}
.icon-lock:before {
  content: "\e904";
}
.icon-magnifying-glass:before {
  content: "\e905";
}
.fa-brands fa-x-twitter:before {
  content: "\e906";
}
.icon-youtube:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-next-arrow:before {
  content: "\e909";
}
.icon-sound:before {
  content: "\e90a";
}
.icon-prev-arrow:before {
  content: "\e90b";
}
.icon-tick:before {
  content: "\e90c";
}
.icon-share:before {
  content: "\e90d";
}
.icon-play:before {
  content: "\e90e";
}
.icon-pause:before {
  content: "\e90f";
}
.icon-disclaimer:before {
  content: "\e910";
}
.icon-instagram-logo:before {
  content: "\e911";
}
.icon-down-arrow:before {
  content: "\e912";
}
.icon-grid:before {
  content: "\e913";
}
.icon-list:before {
  content: "\e914";
}
.icon-full-screen-exit:before {
  content: "\e915";
}
.icon-volume-off:before {
  content: "\e916";
}
.icon-volume-up:before {
  content: "\e917";
}
.icon-fullscreen:before {
  content: "\e918";
}

