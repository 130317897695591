/* News Listing Page */
.sidebar__news-date {
    font-size: rem(14);
    font-family: $font_main_roman;
    display: block;
    margin-bottom: 10px;

    @media #{$tablet} {
    	font-size: rem(12);
    }

    .container-small & {
    	font-size: rem(12);
    }

    .jumbotron-gradient__light-left & {
    	color: $gray__xd;
    }
}

.sidebar__news-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: rem(18);
	font-weight: 500;
	line-height: 1.3;

    > a {
    	color: $white;
    	text-shadow: 0 1px 0 rgba($trueblack,0.7);
    	float: left;
    	width: calc(100% - 105px);

    	&:hover {
    		text-decoration: underline;
    	}

    	@media #{$tablet} {
    		width: calc(100% - 82px);
    	}

    	.component-container &{
    		&:visited {
    			color:$white;
    		}
    	}

    	.jumbotron-gradient__light-left & {
    		color: $gray__xd;
    		text-shadow: none;

    		.component-container & {
    			color: $gray__xd;
    		}
    	}
    }

	.jumbotron-gradient__black-left & {
		a {
			color: $white;
			text-decoration: none;

			&:hover, &:focus {
				text-decoration: underline;
			}
		}
	}

    @media #{$tablet} {
    	font-size: rem(15);
    }

    .container-small & {
    	font-size: rem(15);
    }
}

.sidebar__news-thumb {
	float: left;
}

.video-thumb {
	width: 80px;
	margin-right: 25px;
	box-shadow: 1px 1px 5px rgba($trueblack,0.6);
	position: relative;

	@media #{$tablet} {
		margin-right: 17px;
		width: 64px;
	}

	.bg-primary &, .bg-secondary & {
		a, a:visited {
			text-decoration: none;
			border-bottom: none;
		}
	}

	a, a:visited {
		text-decoration: none !important;
		border-bottom: none !important;
	}

	.container-small & {
    	margin-right: 17px;
		width: 64px;
    }

	img {
		width: 80px;
		height: 80px;
		object-fit: cover;

		@media #{$tablet} {
			width: 64px;
			height: 64px;
		}

		.container-small & {
	    	width: 64px;
			height: 64px;
	    }
	}

	&.video {
		.video-play {
			background: transparent;
			border: none;
			font-size: rem(32);
			color: $white;
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&:hover {
		background: $trueblack;
		img, &.video .video-play {
			opacity: 0.8;
			outline: none;
			text-decoration: none;
		}
	}
}

.video-modal {
	.modal-header {
		padding: 0;
	}

	.modal-body {
		padding: 0;
	}

	video {
		width: 100%;
		height: 505px;
		display: block;

		@media #{$tablet} {
			height: 382px;
		}
		@media #{$mobile} {
			height: 250px;
		}
	}

	.video-js {
		.vjs-big-play-button,
		.vjs-playback-rate{
			display: none;
		}
	}

	.close {
		background: #000;
	    color: #fff;
	    position: absolute;
	    right: -26px;
	    padding: 20px 13px;
	    top: 16px;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    z-index: 12;
    	opacity: 1;

	    @media #{$mobile} {
	    	width: 30px;
	    	height: 30px;
	    	right: 16px;
	    }

	    span {
	    	line-height: 0;
		    display: inline-block;
		    margin-top: -5px;
	    }
	}
}

.modal{
	background: rgba($gray__xd,0.85);

	.close {
		&:after, &:before {
			display: none;
		}
	}

	@media #{$mobile_mini}{
	 	.modal-dialog.modal-lg {
    		max-width: 678px;
    	}
	}
	@media #{$tablet_mini}{
		.modal-dialog.modal-lg {
    		max-width: 800px;
    	}
	}

	@media #{$desktop}{
	 	.modal-dialog.modal-lg {
    		max-width: 900px;
    	}
	}
}

.section-title {
	font-size: rem(34);
	font-weight: bold;
	line-height: 1.2;
	letter-spacing: -1px;
	margin-bottom: 15px;

	@media #{$tablet} {
		font-size: rem(27);
		margin-bottom: 10px;
	}
}

.featured__list {
	border: 1px solid $gray__xl;
	height: 100%;

	.date {
        font-size: rem(14);
        font-family: $font_main_roman;

        @media #{$mobile} {
            font-size: rem(12);
            margin-top: 8px;
        }
	}

	@media #{$mobile} {
		margin-bottom: 15px;
		height: auto;
	}

	@media #{$mobile_sm_only} {
		height: 100%;
	}
}

.featured__image {
	height: 220px;

	img {
		object-fit: cover;
		height: 220px;
	}

	@media #{$tablet} {
		height: 178px;

		img {
			height: 178px;
		}
	}
}

.featured__content {
	padding: 25px 28px;

	@media #{$tablet} {
		padding: 18px 20px;
	}

	@media #{$mobile} {
		padding: 15px 18px;
	}

	h3, .h3 {
		font-size: rem(25);
		font-weight: bold;
		margin-bottom: 10px;

		@media #{$tablet} {
			font-size: rem(20);
		}

		@media #{$mobile} {
			font-size: rem(18);
		}

		a {
			color: $blue;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.featured-section {
	&:last-of-type {
		@media #{$tablet_min} {
			margin-bottom: 3rem;
		}
	}
}

.read-more-link {
    color: $blue;
    font-size: rem(16);
    font-weight: bold;
	font-family: $font__main;
	display: inline-block;

    &:hover,
    &:focus {
        color: $gray__l;
    }

    @media #{$mobile} {
        font-size: rem(14);
    }

}

.hidden{
 	visibility: hidden;
	width: 0!important;
	height: 0!important;
	margin: 0 !important;
}

.news-author {
	margin: 0;
}

.no-js #loadMore {
	display: none;
}

/*Article Page */
.firstcharacter {
	color: $red;
	font-size: rem(100);
	font-family: $font__main;
	font-weight: bold;
	float: left;
	margin-right: 10px;
	line-height: 0.85;

	@media #{$mobile} {
		font-size: rem(80);
	}
}

.sidebar__news-item {
	margin: 30px 0 40px;

	@media #{$mobile} {
		margin: 15px 0 30px;

		&.last {
			margin-bottom: 20px;
		}

		+ .text-right a{
			padding: 8px 20px;
		}
	}

	figure {
		margin: 0;

		.img-fluid {
			width: 100%;
			max-height: 250px;
			object-fit: cover;
			object-position: top;
		}
	}

	h3 {
		background: $gray__xl;
		font-size: rem(20);
		line-height: 1.2;
		font-weight: bold;
		letter-spacing: 0;
		padding: 10px 25px;

		@media #{$tablet} {
			font-size: rem(16);
		}

		@media #{$mobile}{
			padding: 10px 15px;
		}

		@media #{$mobile_sm_max}{
			padding: 10px 20px;
		}

		a {
			display: inline-block;

			&:hover {
				color: $blue;
				text-decoration: underline;
			}
		}
	}
}

.sidebar__news-item-border {
	margin: 0 0 25px;
	border: 1px solid $gray__xl;
	position: relative;

	h3 {
		background: transparent;
		font-size: rem(25);
		padding: 20px 25px;
		margin: 0;

		@media #{$tablet} {
			font-size: rem(18);
			padding: 12px 20px;
		}
	}

	a {
		display: inline-block;

		&:hover {
			color: $blue;
			text-decoration: underline;
		}

		&.text-red:hover {
			color: $red;
		}
	}
}

.gallery-wrap, .engagement-wrap {
	overflow: hidden;
	padding: 5px 0;

	a {
		display: block;
		margin-bottom: 30px;
		position: relative;

		@media #{$mobile} {
			margin-bottom: 20px;
		}

		&:hover .capiton,
		&:focus .capiton  {
			opacity: 1;
			transform: scale(1);
		}
	}

	img {
		width: 100%;
		max-height: 455px;
		object-fit: cover;
		object-position: center;
	}

	.capiton {
		background: rgba(0,0,0,0.7);
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $white;
		font-size: rem(20);
		font-family: $font_main_roman;
		padding: 20px;
		text-align: center;
		transform: scale(0);
		transition: opacity 0.5s, transform 0.5s;

		@media #{$mobile} {
			font-size: rem(16);
		}
	}
}

.mw-80 {
	max-width: 80%;

	@media #{$tablet_lg} {
		max-width: 90%;
	}

	@media #{$mobile} {
		max-width: 100%;
	}
}

.engagement-wrap {
	img {
		height: 100%;
		@media #{$tablet_min} {
			min-height: 300px;
		}
	}

	div {
		overflow: hidden;
	}

	.engagement-content {
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 25px;
		position: absolute;
		bottom: 0;
		z-index: 100;
		opacity: 1;
		transition: 0.5s;

		&.primary-gradient,
		&.dark-gradient,
		&.cool-gray-gradient,
		&.secondary-gradient {
			color: $white;
		}

		&.light-gradient,
		&.light-gray-gradient {
			color: $cool-gray;
		}
	}

	.capiton {
		transform: scale(1);
		transition: transform 0.5s, background-color 0.5s, background-image 0.5s linear, height 0.5s, color 0.5s;

		&:not(.hover-text) {
			opacity: 1;
			height: 50%;
			bottom: 0;
			top: initial;
			align-items: flex-end;
			background: transparent;
			padding-bottom: 25px;
		}

		&.hover-text {
			opacity: 0;
			color: transparent;
			height: 50%;
			font-size: 2rem;
			font-weight: 600;
			line-height: 1;
			background-color: transparent;
			transition-timing-function: ease-out;
			transition: 1s;
			transform: translateY(150%);
			padding-left: 26px;
			padding-right: 26px;
		}

		&.dark-gradient:not(.hover-text) {
			background-image: linear-gradient(0deg, #000 25%, transparent 100%);
			color: $white;
		}

		&.light-gradient:not(.hover-text) {
			background-image: linear-gradient(0deg, #fff 25%, transparent 100%);
			color: $cool-gray;
		}

		&.cool-gray-gradient:not(.hover-text) {
			background-image: linear-gradient(0deg, #474c55 25%, transparent 100%);
			color: $white;
		}

		&.primary-gradient:not(.hover-text) {
			background-image: linear-gradient(0deg, #d9192c 25%, transparent 100%);
			color: $white;
		}

		&.secondary-gradient:not(.hover-text) {
			background-image: linear-gradient(0deg, #0054a6 25%, transparent 100%);
			color: $white;
		}

		&.light-gray-gradient:not(.hover-text) {
			background-image: linear-gradient(0deg, #edeff0 25%, transparent 100%);
			color: $cool-gray;
		}
	}

	a {
		height: 100%;
		margin: 0;

		@media #{$tablet_min} {
			&:hover,
			&:focus {
				&:after {
					position: absolute;
					content: '';
					height: 100%;
					width: 100%;
					top: 0;
					left: 0;
					outline: 2px solid;
					outline-color: currentColor;
					outline-offset: -20px;
				}
			}

			&.primary-gradient,
			&.dark-gradient,
			&.cool-gray-gradient,
			&.secondary-gradient {
				color: transparent;
				transition: 0.5s;

				&:hover,
				&:focus {
					color: $white;
				}
			}

			&.light-gradient,
			&.light-gray-gradient {
				color: transparent;
				transition: 0.5s;

				&:hover,
				&:focus {
					color: $cool-gray;
				}
			}

			&:hover .engagement-content,
			&:focus .engagement-content {
				opacity: 0;

				&.primary-gradient,
				&.dark-gradient,
				&.cool-gray-gradient,
				&.secondary-gradient,
				&.light-gradient,
				&.light-gray-gradient {
					color: transparent;
				}
			}

			&:hover .capiton,
			&:focus .capiton {
				background: transparent;
				background-image: initial;
				color: transparent;
				height: 75%;
				opacity: 0;
				transition: opacity 0.5s linear, color 0.5s;
			}

			&:hover .hover-text,
			&:focus .hover-text {
				opacity: 1;
				height: 100%;
				bottom: 0;
				top: initial;
				align-items: center;
				background: transparent;
				font-family: "Helvetica_Roman", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
				transition-timing-function: ease-in-out;
				transition: opacity 0.5s linear, height 0.5s;
				transform: translateY(0%);

				&.primary-gradient {
					background-image: linear-gradient(0deg, #d9192c 0%, transparent 100%);
					color: $white;
				}

				&.dark-gradient {
					background-image: linear-gradient(0deg, #000 0%, transparent 100%);
					color: $white;
				}

				&.light-gradient {
					background-image: linear-gradient(0deg, #fff 0%, transparent 100%);
					color: $cool-gray;
				}

				&.secondary-gradient {
					background-image: linear-gradient(0deg, #0054a6 0%, transparent 100%);
					color: $white;
				}

				&.cool-gray-gradient {
					background-image: linear-gradient(0deg, #474c55 25%, transparent 100%);
					color: $white;
				}

				&.light-gray-gradient {
					background-image: linear-gradient(0deg, #edeff0 25%, transparent 100%);
					color: $cool-gray;
				}
			}
		}
	}
}

.fancybox-caption {
	display: none;

	@media #{$tablet} {
		display: block;
	}
}
.fancybox-button {
	background: transparent;
	height: auto;
	width: auto;
}
.fancybox-close-small {
	background: $oil;
	height: 44px;
	width: 44px;
}
.fancybox-slide--image {
	padding: 45px;

	.fancybox-content {
		position: relative;
		margin: 0 auto;
		transform: translate(0%,-50%) !important;
		top: 50%;

		.fancybox-button {
			position: absolute;
			top: 0;
			left: auto;
			right: 0;
			opacity: 1;

			@media #{$mobile} {
				background: $trueblack;
			}

			&:hover {
				opacity: 0.8;
			}

			+ .fancybox-button {
				display: none;
			}
		}

		.fancybox-image {
			position: relative;
		}

		.caption {
			color: $white;
			text-align: center;
			margin-top: 5px;

			@media #{$desktop}{
				//display: none;
			}

		}
	}
}
.ekko-lightbox {
	&.modal {
		display: block !important;
	}

	.modal-header {
	    padding: 0;
	    border: 0;
	}

	.modal-title {
		display: none;
	}

	.modal-dialog {
	    margin: 50px auto 0;
	    width: 95%;

		@media #{$tablet_min} {
			padding: 0 40px;
		}
	}

	.modal-footer {
	    justify-content: center;
	    border-top: none;
	    text-align: center !important;

	    @media #{$tablet_min} {
	    	display: none;
	    }

	    @media #{$mobile} {
	    	display: block;
	    	font-size: rem(15);
	    	padding-top: 0;
	    }
	}

	.close {
		position: absolute;
	    right: -20px;
	    top: 15px;
	    padding: 10px 10px 12px;
	    background: rgba(0,0,0,0.9);
	    opacity: 1;
	    color: $white;
	    text-shadow: none;
	    line-height: 15px;
	    z-index: 10;

	    @media #{$mobile} {
	    	right: 16px;
	    	top: 15px;
	    }
	}
}