/* Staff Page */
.employee__wrap {
  margin-top: 35px;

  @media #{$tablet} {
    margin-top: 10px;
  }

  @media #{$mobile} {
    margin-top: 25px;
  }
}

.employee__list-item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 22px;

  @media #{$mobile_sm_max} {
    //border: 1px solid $gray__xl;
    //padding: 18px 15px;
    margin-bottom: 16px;
  }
}

.employee__image {
  width: 220px;
  max-height: 220px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  @media #{$tablet} {
    width: 176px;
  }

  @media #{$mobile_sm_max} {
    width: 100px;
    height: auto;
  }
}

.employee__details {
  border: 1px solid $gray__xl;
  width: calc(100% - 220px);
  padding: 43px 40px 15px 43px;

  @media #{$tablet} {
    width: calc(100% - 176px);
    padding: 32px 32px 10px;
  }

  @media #{$mobile_sm_max} {
    //border: none;
    width: calc(100% - 100px);
    padding: 9px 0 9px 10px;
  }

  h2,
  .h2 {
    color: $red;
    font-size: rem(30);
    font-family: $font_main_bdCon;
    line-height: 0.9;
    letter-spacing: -1px;
    margin: 0;

    @media #{$tablet} {
      font-size: rem(24);
      line-height: 1.2;
    }
  }

  > h3,
  > .h3 {
    font-size: rem(25);
    font-weight: bold;

    @media #{$tablet} {
      font-size: rem(20);
    }

    @media #{$mobile} {
      font-size: rem(16);
    }
  }
}

.employee__details-list {
  font-size: rem(16);
  font-family: $font_main_roman;
  line-height: 1.5;
  margin-top: 15px;

  @media #{$tablet} {
    font-size: rem(13);
  }

  @media #{$mobile} {
    margin-top: 10px;
  }
}

.employee__details-info {
  a {
    display: inline-block;
    text-decoration: none;
    border-bottom: none;

    &:hover,
    &:focus {
      color: $blue;
      text-decoration: underline;
      border-bottom: none;
    }
  }

  @media #{$mobile} {
    margin-bottom: 10px;
  }
}

/*Red staff btn border bottom*/
.employee__details-info a.btn.btn__red {
  display: inline-block;
  border-bottom: 2px #d9192c solid;
}

/* 
/ Staff Single Page
*/

.employee-position {
  font-size: rem(25);
  font-weight: bold;

  @media #{$tablet} {
    font-size: rem(20);
  }
}

.profile-pic {
  overflow: hidden;
  max-height: 300px;

  img {
    object-fit: cover;
    width: 100%;
  }

  @media #{$mobile} {
    max-height: 500px;
  }
}

/*
Business Directory
 */

.business-directory {
  .employee__details {
    width: calc(100% - 240px);

    h2,
    .h2 {
      color: $red;
      padding-bottom: 10px;

      a,
      a:visited {
        color: $red;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  .employee__image {
    width: 220px;

    img {
      width: 100%;
      height: auto;
    }

    &.image-container {
      &.compat-object-fit {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;

        @media #{$mobile} {
          background-position: top center;
        }
      }
    }
  }

  .employee__details-info {
    .employee__details-content {
      overflow-wrap: anywhere;

      hr {
        display: block;
        border-right: 0;

        @media #{$tablet_min} {
          display: none;
        }
      }

      @media #{$tablet_min} {
        border-right: 1px solid #dee2e6;
      }
    }
  }
}
