/* --------------------------
 Site Content
----------------------------*/
a {
    color: $blue;
    @include transition(all 0.5s ease);

    &:hover,
    &:focus {
        color: $red;
        text-decoration: underline;
    }

    &:visited:not(.btn) {
        color: $gray__xd;
    }

    &.no-visited {
        color: $blue;

        &:visited {
            color: $blue;
        }
    }
}

ul {
    list-style: none;
    margin: 24px 0 24px 0;
    padding: 0;
}

.main-content ul:not(.masonry-gallery):not(.uwg-links):not(.tick-list):not(.nav-tabs):not(.item-list):not(.search__category-list):not(.search__result-list):not(.search-list):not(.sidebar__news-list):not(.pagination):not(.search__filter-list), .list-bullet {
    margin: 10px 0;

    ol:not(.breadcrumb):not(.carousel-indicators) {
        counter-reset: item;

        > li {

            &:before {
                content: counter(item) ". ";
            }
        }
    }

    li {
        position: relative;
        padding-left: 20px;
        margin-bottom: 10px;
        font-size: rem(20);
        font-family: $font_main_roman;
        line-height: 1.3;

        @media #{$tablet} {
            font-size: rem(16);
            line-height: 1.5;
        }

        &:before {
            content: '\002022';
            color: $red;
            left: 0;
            position: absolute;
            top: 3px;
            font-size: rem(15);
        }
    }

    .bg-primary &, .bg-secondary & {
        li {
            &:before {
                color: $white !important;
            }
        }
    }
}

.main-content .bg-primary ul:not(.masonry-gallery):not(.uwg-links):not(.tick-list):not(.nav-tabs):not(.item-list):not(.search__category-list):not(.search__result-list):not(.search-list):not(.sidebar__news-list):not(.pagination):not(.search__filter-list),
.bg-primary .list-bullet,
.main-content .bg-secondary ul:not(.masonry-gallery):not(.uwg-links):not(.tick-list):not(.nav-tabs):not(.item-list):not(.search__category-list):not(.search__result-list):not(.search-list):not(.sidebar__news-list):not(.pagination):not(.search__filter-list),
.bg-secondary .list-bullet {
    li {
        &:before {
            color: $white;
        }
    }
}

.main-content .uwg-content ul {
    margin-top: 0;
}

hr {
    border-top: 1px solid $trueblack__xl;
}


/* Home page Banner */
.banner-section {
    h1 {
        font-size: rem(80);
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 0.9;

        @media #{$desktop_sm} {
            font-size: rem(70);
        }

        @media #{$tablet} {
            font-size: rem(60);
        }

        @media #{$mobile} {
            font-size: rem(40);
        }
    }
}

/* SLIDER */
.banner__right-carousel {
    padding-bottom: 40px;

    .carousel-item {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .carousel-control-next,
    .carousel-control-prev {
        top: auto;
        opacity: 1;
        color: $gray__xd;
        z-index: 40;
        bottom: -5px;
        width: auto;
        display: block;

        &:hover, &:focus {
            color: $red;
            text-decoration: none;
        }

        &:focus {
            outline: 3px solid #00a5e3;
        }
    }

    .playpause_btn {
        color: $gray__xd;
        font-size: rem(35);
        position: absolute;
        left: 140px;
        text-decoration: none;

        &:hover, &:focus {
            color: $red;
            text-decoration: none;
        }

        &:focus {
            outline: 3px solid #00a5e3;
        }

        &.play .icon-pause:before {
            content: "\e90e";
        }

        @media #{$desktop_lg} {
            left: 120px;
        }

        @media #{$desktop_sm_only} {
            left: 110px;
        }

        @media #{$tablet} {
            left: 130px;
        }

        @media #{$mobile} {
            left: 105px;
        }

        .container-small & {
            left: 105px;
        }
    }

    .carousel-control-prev-icon, .carousel-control-next-icon {
        height: 35px;
    }

    .carousel-control-prev {
        left: 42px;

        @media #{$desktop_lg} {
            left: 30px;
        }

        @media #{$tablet_only} {
            left: 31px;
        }

        @media #{$desktop_sm_only} {
            left: 20px;
        }

        @media #{$mobile} {
            left: 18px;
        }

        .container-small & {
            left: 18px;
        }
    }

    .carousel-control-next {
        right: auto;
        left: 90px;

        @media #{$desktop_lg} {
            left: 75px;
        }

        @media #{$tablet_only} {
            left: 80px;
        }

        @media #{$desktop_sm_only} {
            left: 66px;
        }

        @media #{$mobile} {
            left: 60px;
        }

        .container-small & {
            left: 60px;
        }
    }

    .carousel-indicators {
        bottom: 2px;
        margin-right: 0;
        justify-content: flex-start;
        margin-left: 220px;

        @media #{$desktop_lg} {
            margin-left: 180px;
        }

        @media #{$tablet} {
            margin-left: 200px;
        }

        @media #{$desktop_sm_only} {
            margin-left: 170px;

            li {
                width: 20px;
            }
        }

        @media #{$mobile} {
            margin-left: 175px;
        }

        .container-small & {
            margin-left: 175px;
        }


        li {
            margin: 0;
            background: $gray__xd;
            text-indent: 0;
            border: none;
            opacity: 1;
            position: relative;
            height: 2px;

            span {
                font-size: rem(12);
                position: absolute;
                top: -7px;
            }

            &:first-child span {
                left: -16px;
            }

            &:last-child span {
                right: -18px;
            }

            &.active {
                background: $red;
                color: $red;
            }
        }
    }
}

.bg-secondary, .bg-primary {

  .banner__right-carousel {

    .carousel-indicators {

      li {

        &.active {
          background: $white;
          color: $white;
        }

      }

    }

  }

}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background: none;
    font-size: rem(35);
    width: 35px;
}

/*
* Cinemagraphs
 */

.ff-container {
    .ff-canvas {
        position: inherit !important;
    }
}

/*
* Other Pages
*/

@import "admissions";
@import "dates-and-deadlines";
@import "academics-program";
@import "academics-college";
@import "atoz-search";
@import "library";
@import "event";
@import "news";
@import "staff";