/* COLOR VARS */

$blue: #0054a6;

$yellow: #f1c920;
$green: #1D6F61;
$oil: #0d0d0d;
$oil__d: #2A2A2A;
$oil__xd: #27292F;

$red: #d9192c;
$green: #108300;
$gold: #e4a102; 

/* GRAY TONES */
$white: #ffffff;
$trueblack: #000000;
$trueblack__l: #dadada;
$trueblack__xl: #ddd;

$gray__xd: #474c55;
$gray__l: #737778;
$gray__xl: #edeff0;
$gray__xxl: #f4f4f4;
$gray__xxxl: #FAFAFA;

$cool-gray: #474c55;
$light-gray: #edeff0;