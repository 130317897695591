/* --------------------------
 Site Component
----------------------------*/

/* jumbotron */
.jumbotron {
  position: relative;
  overflow: hidden;
  padding: 0;
  border-radius: 0;
  margin: 0;
  background: transparent;

  .jumbotron-banner__image {
    @media #{$tablet_min} {
      @include absolute-cover();
    }

    @media #{$mobile} {
      height: 300px;
      overflow: hidden;
    }

    .container-small & {
      height: 300px;
      position: relative;
      top: auto;
      left: auto;
      bottom: auto;
      right: auto;
    }
  }

  &.jumbotron-video-banner {
    .vjs-fluid {
      padding-top: 50.25%;
    }

    .content-wrap {
      @media #{$tablet_min} {
        width: 38%;
        padding-right: 0;
      }

      @media #{$tablet_min} {
        position: absolute;
        left: 0;
        top: 0;
      }

      @media #{$mobile} {
        display: block !important;
      }
    }
  }

  .content-wrap {
    position: relative;
    z-index: 2;
    padding: 8% 10%;
    max-width: 720px;
    width: 75%;

    @media #{$tablet_min} {
      width: 65%;
    }

    @media #{$desktop_xlg} {
      width: 600px;
    }

    @media #{$desktop_lg} {
      width: 435px;
      padding-right: 0;
    }

    @media #{$tablet_lg} {
      width: 50%;
      padding: 10% 5%;
    }

    @media #{$mobile} {
      padding: 20px;
      width: 100%;
      max-width: 100%;
    }

    &.hide {
      display: none;
    }

    .container-small & {
      padding: 20px;
      width: 100%;

      .btn {
        font-size: rem(15);
      }
    }

    h1 {
      font-family: $font__helveticaBlkCon;
      color: $white;
      text-shadow: 0px 1px 0px $gray__xd;

      @media #{$mobile} {
        letter-spacing: -1px;
      }

      .container-small & {
        font-size: rem(40);
      }
    }

    a:not(.btn):not(.video-play) {
      text-decoration: none;
      border-bottom: 2px solid;

      &:hover,
      &:focus {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        border-bottom: 2px solid transparent;
      }
    }

    p {
      font-family: $font__main;
      font-size: rem(26);
      font-weight: 500;
      color: $white;
      max-width: 390px;
      line-height: 1.2;
      margin-bottom: 40px;
      margin-top: 20px;

      @media #{$tablet} {
        font-size: rem(19);
      }

      @media #{$mobile} {
        font-size: rem(16);
        max-width: 100%;
        margin-bottom: 20px;
        margin-top: 0;
      }

      .container-small & {
        font-size: rem(16);
        max-width: 100%;
        margin-bottom: 20px;
        margin-top: 0;
      }
    }
  }

  &.jumbotron-video-banner {
    h1 {
      color: $white;
      text-transform: uppercase;
    }

    @media #{$mobile} {
      background: $gray__xd;
    }
  }

  &.with-white-bg {
    .jumbotron-banner__image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      @media #{$tablet_min} {
        background-position: top right;

        &:before {
          content: "";
          @include absolute-cover();
          @include gradient-x(
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0),
            25%,
            75%
          );
          width: 100%;
          z-index: 1;

          .container-small & {
            display: none;
          }
        }
      }
    }

    h1 {
      color: $red;
      text-transform: uppercase;
    }

    p {
      color: $gray__xd;
    }
  }

  &.with-dark-bg {
    .jumbotron-banner__image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      &:before {
        content: "";
        @include absolute-cover();
        @include linear-gradient(
          to right,
          rgba(42, 44, 47, 0.95) 40%,
          rgba(42, 44, 47, 0.95) 40%,
          rgba(42, 44, 47, 0) 100%
        );
        width: 60%;
        z-index: 1;

        .container-small & {
          display: none;
        }

        @media #{$mobile} {
          display: none;
        }
      }
    }

    h1 {
      color: $white;
      text-transform: uppercase;
    }

    .content-wrap {
      a:not(.btn):not(.video-play) {
        color: $white;
      }

      @media #{$mobile} {
        background: $gray__xd;
      }
    }
  }
}

.jumbotron-gradient__black,
.jumbotron-no-gradient {
  padding: 54px 30px 60px 60px;
  min-height: 584px;

  &.component-jumbotron {
    padding-left: 30px;

    @media #{$mobile} {
      padding: 30px 20px;
      min-height: 482px;

      .jumbotron-gradient__image {
        @include absolute-cover();
        height: auto;
      }
    }
  }

  @media #{$desktop_xlg} {
    padding: 54px 30px 60px 40px;
  }

  @media #{$tablet} {
    padding: 50px 30px 50px 20px;
    min-height: 586px;
  }

  @media #{$mobile} {
    padding: 0;
    min-height: 50px;

    .col-md-8,
    .align-items-end {
      padding: 0;
    }

    .col-md-4 {
      margin-bottom: 30px;
    }
  }

  .container-small & {
    padding: 0;
    min-height: 50px;
  }

  .col-md-8 {
    .container-small & {
      padding: 0;
      width: 100%;
      max-width: 100%;
      flex: 1;
    }
  }

  .col-md-4 {
    padding: 0;
  }

  .isIE & {
    .align-items-end.col-md-5 {
      @media #{$tablet_min} {
        bottom: 60px;
        position: absolute;
      }
    }
  }

  .jumbotron-gradient__image,
  .jumbotron-no-gradient__image {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;

    @media #{$tablet_min} {
      @include absolute-cover();

      .container-small & {
        position: relative;
        width: 100%;
        left: auto;
        top: auto;
        bottom: auto;
        right: auto;
        height: 205px;
      }
    }

    @media #{$mobile} {
      height: 205px;
    }

    &:before {
      content: "";
      @include absolute-cover();
      top: 20%;
      @include linear-gradient(
        to bottom,
        rgba(42, 44, 47, 0) 40%,
        rgba(42, 44, 47, 0.5) 50%,
        rgba(42, 44, 47, 0.8) 100%
      );
      width: 100%;
      z-index: 101;
    }

    @media #{$mobile} {
      position: relative;
      width: 100%;
    }
  }

  .jumbotron-gradient__image.parallax-slider,
  .jumbotron-no-gradient__image.parallax-slider {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 0;
    background-color: $gray__xd;
  }

  &.jumbotron-fluid .jumbotron__content {
    @media #{$tablet_min} {
      max-width: 400px;
    }
  }

  .jumbotron__content {
    color: $white;
    padding-right: 30px;
    position: relative;
    z-index: 2;

    .container-small & {
      background: $gray__xd;
      padding: 30px 20px;
      width: 100%;
      margin-top: -1px;
      max-width: 100%;
    }

    @media #{$mobile} {
      background: $gray__xd;
      padding: 30px 20px;
      margin-bottom: 30px;
      width: 100%;
      margin-top: -1px;
    }

    h2 {
      font-size: rem(60);
      font-family: $font__helveticaBlkCon;
      line-height: 1;
      letter-spacing: 1px;
      margin: 0;
      text-shadow: 0 1px 0 $gray__xd;
      text-transform: uppercase;
      color: $white;

      @media #{$tablet} {
        font-size: rem(48);
      }

      @media #{$mobile} {
        font-size: rem(40);
        color: $white;
      }

      .container-small & {
        font-size: rem(40);
      }
    }

    h3,
    p {
      font-family: $font__main;
      font-size: rem(26);
      font-weight: 500;
      color: $white;
      line-height: 1.2;
      margin: 0 0 24px;
      text-shadow: 0px 1px 0px $oil__d;

      @media #{$tablet} {
        font-size: rem(20);
      }

      @media #{$mobile} {
        font-size: rem(16);
        max-width: 100%;
        margin-bottom: 20px;
        margin-top: 0;
        color: $white;
      }

      .container-small & {
        font-size: rem(16);
        max-width: 100%;
        margin-bottom: 20px;
        margin-top: 0;
      }
    }
  }
}

.jumbotron-gradient__light-bottom {
  padding: 54px 30px 60px 60px;
  min-height: 584px;

  &.component-jumbotron {
    padding-left: 30px;

    @media #{$mobile} {
      padding: 30px 20px;
      min-height: 482px;

      .jumbotron-gradient__image {
        @include absolute-cover();
        height: auto;
      }
    }
  }

  @media #{$desktop_xlg} {
    padding: 54px 30px 60px 40px;
  }

  @media #{$tablet} {
    padding: 50px 30px 50px 20px;
    min-height: 586px;
  }

  @media #{$mobile} {
    padding: 0;
    min-height: 50px;

    .col-md-8,
    .align-items-end {
      padding: 0;
    }

    .col-md-4 {
      margin-bottom: 30px;
    }
  }

  .container-small & {
    padding: 0;
    min-height: 50px;
  }

  .col-md-8 {
    .container-small & {
      padding: 0;
      width: 100%;
      max-width: 100%;
      flex: 1;
    }
  }

  .col-md-4 {
    padding: 0;
  }

  .isIE & {
    .align-items-end.col-md-5 {
      @media #{$tablet_min} {
        bottom: 60px;
        position: absolute;
      }
    }
  }

  .jumbotron-gradient__image,
  .jumbotron-no-gradient__image {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;

    @media #{$tablet_min} {
      @include absolute-cover();

      .container-small & {
        position: relative;
        width: 100%;
        left: auto;
        top: auto;
        bottom: auto;
        right: auto;
        height: 205px;
      }
    }

    @media #{$mobile} {
      height: 205px;
    }

    &:before {
      content: "";
      @include absolute-cover();
      top: 20%;
      @include linear-gradient(
        to bottom,
        rgba(42, 44, 47, 0) 40%,
        rgba(42, 44, 47, 0.5) 50%,
        rgba(42, 44, 47, 0.8) 100%
      );
      width: 100%;
      z-index: 101;
    }

    @media #{$mobile} {
      position: relative;
      width: 100%;
    }
  }

  .jumbotron-gradient__image.parallax-slider,
  .jumbotron-no-gradient__image.parallax-slider {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 0;
    background-color: $white;
  }

  &.jumbotron-fluid .jumbotron__content {
    @media #{$tablet_min} {
      max-width: 400px;
    }
  }

  .jumbotron__content {
    color: $gray__xd;
    padding-right: 30px;
    position: relative;
    z-index: 2;

    .container-small & {
      background: $white;
      padding: 30px 20px;
      width: 100%;
      margin-top: -1px;
      max-width: 100%;
    }

    @media #{$mobile} {
      background: $white;
      padding: 30px 20px;
      margin-bottom: 30px;
      width: 100%;
      margin-top: -1px;
    }

    h2 {
      font-size: rem(60);
      font-family: $font__helveticaBlkCon;
      line-height: 1;
      letter-spacing: 1px;
      margin: 0;
      text-shadow: 0 1px 0 $gray__xd;
      text-transform: uppercase;
      color: $red;

      @media #{$tablet} {
        font-size: rem(48);
      }

      @media #{$mobile} {
        font-size: rem(40);
        color: $red;
      }

      .container-small & {
        font-size: rem(40);
      }
    }

    h3,
    p {
      font-family: $font__main;
      font-size: rem(26);
      font-weight: 500;
      color: $gray__xd;
      line-height: 1.2;
      margin: 0 0 24px;
      text-shadow: 0px 1px 0px $oil__d;

      @media #{$tablet} {
        font-size: rem(20);
        text-shadow: none;
      }

      @media #{$mobile} {
        font-size: rem(16);
        max-width: 100%;
        margin-bottom: 20px;
        margin-top: 0;
        color: $gray__xd;
      }

      .container-small & {
        font-size: rem(16);
        max-width: 100%;
        margin-bottom: 20px;
        margin-top: 0;
      }
    }
  }
}

.jumbotron-gradient__light-bottom {
  .jumbotron-gradient__image {
    &:before {
      top: 0;
      @include linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.2) 30%,
        rgba(255, 255, 255, 0.85) 100%
      );
    }
  }
}

.jumbotron-no-gradient {
  .jumbotron-no-gradient__image {
    &::before {
      content: none;
    }

    &.image-container {
      &.parallax-slider {
        background-size: cover;
      }
    }
  }
}

.jumbotron-with-sidebar {
  @media #{$mobile} {
    padding: 0 15px 40px;
  }

  .container-small & {
    padding: 0 15px 40px;
  }
}

.jumbotron-gradient__black-small {
  padding: 54px 40px;
  justify-content: center;
  height: 100%;

  @media #{$tablet} {
    padding: 35px 30px;
  }

  @media #{$mobile} {
    padding: 0;
    height: auto;
  }

  .jumbotron-gradient__image {
    &:before {
      top: 46%;
      @include linear-gradient(
        to bottom,
        rgba(42, 44, 47, 0) 0%,
        rgba(42, 44, 47, 0.8) 30%,
        rgba(42, 44, 47, 0.85) 100%
      );

      @media #{$mobile} {
        @include linear-gradient(
          to bottom,
          rgba(42, 44, 47, 0) 0%,
          rgba(42, 44, 47, 0.5) 30%,
          rgba(42, 44, 47, 0.85) 100%
        );
      }
    }
  }

  .jumbotron-gradient__bottom {
    &:before {
      top: 0;
      @include linear-gradient(
        to bottom,
        rgba(71, 76, 85, 0.1) 0%,
        rgba(71, 76, 85, 0) 85%,
        rgba(42, 44, 47, 1) 100%
      );
    }
  }

  .content-col {
    @media #{$mobile} {
      width: 100%;
    }
  }

  .jumbotron__content {
    @media #{$mobile} {
      margin-bottom: 0;
    }

    h2 {
      font-size: rem(65);
      font-family: $font__helveticaBlkCon;
      letter-spacing: -2px;
      line-height: 0.9;
      margin: 0;
      text-shadow: 0 1px 0 $gray__xd;
      text-transform: uppercase;

      @media #{$tablet} {
        font-size: rem(48);
      }

      @media #{$mobile} {
        font-size: rem(40);
      }
    }

    p {
      font-size: rem(24);
      font-family: $font_main_roman;
      line-height: 1.4;
      margin-bottom: 15px;
      text-shadow: 0 1px 0 $oil__d;

      @media #{$tablet} {
        font-size: rem(16);
      }
    }

    .btn {
      font-size: rem(20);
      padding: 10px 40px;

      @media #{$tablet} {
        font-size: rem(15);
      }

      .container-small & {
        font-size: rem(15);
      }
    }
  }

  .container p {
    @media #{$tablet} {
      font-size: rem(20);
      font-weight: 600;
      font-family: $font__main;
    }
    @media #{$mobile} {
      font-size: rem(18);
    }
  }
}

.jumbotron-gradient__light-small {
  padding: 54px 40px;
  justify-content: center;
  height: 100%;

  @media #{$tablet} {
    padding: 35px 30px;
  }

  @media #{$mobile} {
    padding: 0;
    height: auto;
  }

  .jumbotron-gradient__image {
    &:before {
      top: 0;
      @include linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.2) 85%,
        rgba(255, 255, 255, 0.85) 100%
      );

      @media #{$mobile} {
        @include linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.1) 0%,
          rgba(255, 255, 255, 0.2) 85%,
          rgba(255, 255, 255, 0.85) 100%
        );
      }
    }
  }

  .jumbotron-gradient__bottom {
    &:before {
      top: 0;
      @include linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.2) 85%,
        rgba(255, 255, 255, 0.85) 100%
      );
    }
  }

  .content-col {
    @media #{$mobile} {
      width: 100%;
    }
  }

  .jumbotron__content {
    @media #{$mobile} {
      margin-bottom: 0;
    }

    h2 {
      font-size: rem(65);
      font-family: $font__helveticaBlkCon;
      letter-spacing: -2px;
      line-height: 0.9;
      margin: 0;
      text-shadow: 0 1px 0 $gray__xd;
      text-transform: uppercase;

      @media #{$tablet} {
        font-size: rem(48);
      }

      @media #{$mobile} {
        font-size: rem(40);
      }
    }

    p {
      font-size: rem(24);
      font-family: $font_main_roman;
      line-height: 1.4;
      margin-bottom: 15px;
      text-shadow: 0 1px 0 $oil__d;

      @media #{$tablet} {
        font-size: rem(16);
      }
    }

    .btn {
      font-size: rem(20);
      padding: 10px 40px;

      @media #{$tablet} {
        font-size: rem(15);
      }

      .container-small & {
        font-size: rem(15);
      }
    }
  }

  .container p {
    @media #{$tablet} {
      font-size: rem(20);
      font-weight: 600;
      font-family: $font__main;
    }
    @media #{$mobile} {
      font-size: rem(18);
    }
  }
}

.jumbotron-min-height {
  height: 620px;

  .container-small & {
    height: auto;
  }

  @media #{$desktop_sm_only} {
    &.jumbotron .jumbotron__dropdown {
      min-height: 524px;
    }
  }

  @media #{$tablet} {
    height: 591px;
  }

  @media #{$mobile} {
    height: auto;
  }

  .container {
    height: 100%;
    .row {
      height: 100%;
    }
  }
}

.jumbotron-sidebar {
  padding: 60px 80px 54px 56px;

  @media #{$tablet} {
    padding: 42px 45px 35px;
  }

  @media #{$mobile} {
    padding: 25px 25px 30px;
  }

  h2 {
    font-size: rem(34);
    line-height: 1.1;
    font-weight: bold;
    margin-bottom: 20px;

    @media #{$tablet} {
      font-size: rem(27);
      margin-bottom: 15px;
    }

    .container-small & {
      font-size: rem(27);
      margin-bottom: 15px;
    }
  }

  .container-small & {
    padding: 25px 25px 30px;

    p,
    .tick-list li {
      font-size: rem(16);
    }

    .btn {
      font-size: rem(15);
    }
  }
}

.jumbotron__image {
  object-fit: cover;

  @media #{$mobile} {
    height: 204px;
    width: 100%;
  }

  .no-js & {
    width: 100%;
    position: absolute;
    height: 100%;
  }
}

.jumbotron__dropdown {
  width: 100%;

  .jumbotron & {
    min-height: 428px;

    @media #{$tablet} {
      min-height: 380px;
    }

    @media #{$mobile} {
      min-height: 20px;
    }
  }

  .card {
    border: none;
    border-radius: 0;
    background: rgba($white, 0.9);
    width: 100%;
    overflow: visible;
  }

  .card-header {
    background: transparent;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
  }

  .dropdown__header {
    width: 100%;
    position: relative;

    a {
      color: $white;
      background: $red;
      display: block;
      font-size: rem(18);
      font-weight: bold;
      padding: 15px 45px 15px 30px;
      border-bottom: none !important;

      &[aria-expanded="true"] {
        .fa:before {
          content: "\f106";
        }
      }

      &:hover {
        background: $gray__xd;
        text-decoration: underline;
      }

      &:not(.btn):visited {
        color: $white;
      }

      @media #{$tablet_max} {
        font-size: rem(15);
      }

      @media #{$mobile} {
        padding: 10px 45px 10px 20px;
      }
    }

    .fa {
      font-size: rem(35);
      line-height: 0.6;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }

    @media #{$tablet} {
      font-size: rem(15);

      .fa {
        font-size: rem(27);
      }
    }
  }

  .collapse.open {
    display: block;
  }

  .card-body {
    padding: 30px 25px;
    border: 1px solid $trueblack__xl;
    border-top: none;
  }

  .uwg-links {
    padding: 5px 0 15px;

    li {
      font-family: $font__main;
      font-size: rem(16);
      font-weight: 500;
      line-height: 20px;
      border-bottom: none;

      a {
        hyphens: auto;
        text-decoration: none;
        border-bottom: none !important;
        @include transition(all 0.5s ease !important);

        &:hover {
          color: $gray__xd;
          text-decoration: underline;
        }
      }
    }
  }

  .uwg-social {
    margin-top: 30px;

    .uwg-social-title {
      font-size: rem(14);
      text-transform: uppercase;
      margin-bottom: 15px;

      @media #{$tablet} {
        font-size: rem(12);
      }
    }

    .uwg-social-icons {
      margin-left: -18px;
    }

    a {
      color: $red;
      font-size: rem(22);
      margin-left: 18px;

      &:hover {
        color: $gray__xd;
      }

      .fa {
        font-size: rem(25);
      }
    }
  }
}

/* BUTTONS */
.btn {
  border: 2px solid;
  border-radius: 0;
  padding: 12px 40px;
  font-size: rem(18);
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0;
  height: auto;
  @include transition(all 0.5s ease);
  box-shadow: none;
  cursor: pointer;
  margin: 3px;

  &.btn-lg {
    padding: 15px 40px;
    font-size: rem(20);
    line-height: 1.5;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
    box-shadow: none;
  }

  &:focus {
    outline: 3px solid #00a5e3;
  }

  @media #{$tablet} {
    font-size: rem(15);
    padding: 9px 32px;
  }

  @media #{$mobile} {
    padding: 8px 32px;
  }
}

a.btn,
span.btn,
span.btn a {
  text-decoration: none !important;

  &:hover,
  &:focus {
    text-decoration: underline !important;
  }
}

span.btn a {
  border-bottom: none !important;
}

.btn__mw-160 {
  min-width: 160px;
}

.btn-sm-full {
  @media #{$mobile} {
    width: 100%;
  }
}

.btn__red,
a:not([href]):not([tabindex]).btn__red {
  background: $white;
  border-color: $red;
  color: $red;

  a {
    color: $red;
  }

  &:hover,
  &:focus {
    background: $red;
    color: $white;
    text-decoration: none;

    a {
      color: $white;
    }
  }

  &:visited {
    color: $red;

    &:hover,
    &:focus {
      color: $white;
    }
  }
}

.btn__white,
a:not([href]):not([tabindex]).btn__white {
  background: rgba($white, 0);
  border-color: $white;
  color: $white;

  a {
    color: $white;
  }

  &:hover,
  &:focus {
    background: $white;
    color: $gray__xd;

    a {
      color: $gray__xd;
    }
  }

  &:visited {
    color: $white;
    &:hover,
    &:focus {
      color: $gray__xd;
    }
  }
}

.btn-primary,
a:not([href]):not([tabindex]).btn-primary {
  background: $white;
  border: 2px solid $blue;
  color: $blue;
  position: relative;

  &:hover,
  &:focus {
    background: rgba($blue, 1);
    color: $white;

    a {
      color: $white;
    }
  }

  &:visited {
    color: $blue;
    &:hover,
    &:focus {
      color: $white;
    }
  }
}

span.btn-primary a {
  color: $blue;

  &:focus {
    color: $blue;
  }
}

.btn-sm {
  padding: 6px 30px 7px;
  font-size: rem(14);
  font-family: $font_main_roman;
}

/* BREADCRUMB */
.breadcrumb {
  background: transparent;
  padding: 0;

  li {
    font-size: rem(14);
    font-family: $font_main_roman;
    line-height: 1.2;
    margin: 0;
  }

  a {
    color: $blue;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    color: $blue;
  }

  .breadcrumb-item.active {
    color: $gray__xd;

    &:before {
      color: $gray__xd;
    }
  }
}

/* TAGS */
.tag-container {
  .btn,
  button {
    display: inline-block;
    margin-right: 10px;
    border-radius: 50px !important;
    background-color: $white;
    border: 1px solid $gray__xl;
    color: $blue;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: rem(14);
    font-family: $font_main_roman;
    padding: 7px 20px;
    position: relative;
    /*@include transition(all 0.5s ease);*/

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: $gray__xl;
      color: $gray__xd;
      border-color: $gray__xl;
    }

    .bg-gray &:not(.active):hover {
      background-color: $trueblack__xl;
    }

    &.btn-resources {
      width: 187px;

      @media #{$mobile_sm} {
        width: 100%;
      }
    }

    &.active {
      padding: 7px 30px 7px 20px;

      @media #{$tablet_lg} {
        padding: 7px 30px 7px 18px;
      }

      @media #{$tablet_max} {
        padding: 5px 30px 5px 22px;
      }

      &:after {
        content: "\e90c";
        font-family: "uwg-icon";
        color: $white;
        font-weight: bold;
        position: absolute;
        right: 10px;
        top: 7px;

        @media #{$tablet_max} {
          font-size: rem(12);
        }
      }
    }

    &.active,
    &:focus {
      background: $red;
      border-color: $red;
      color: $white;
    }

    &.btn-date {
      span {
        color: $gray__xd;
        font-size: rem(22);
        line-height: 0.5;
        vertical-align: middle;
        margin-left: 5px;
      }

      &.active {
        padding: 7px 20px;

        @media #{$tablet_lg} {
          padding: 7px 18px;
        }

        span {
          color: $white;
        }

        @media #{$tablet_max} {
          padding: 5px 22px;
        }

        &:after {
          content: "";
        }
      }

      &:focus,
      &:visited,
      &.selected {
        span {
          color: $white;
        }
      }
    }

    @media #{$tablet_lg} {
      padding: 7px 18px;
    }

    @media #{$tablet_max} {
      padding: 5px 22px;
    }
  }

  &.tag-container__dropdown {
    @media #{$mobile} {
      text-align: left;
      background: $white;
      border-radius: 18px;
      font-size: rem(14);
      font-family: $font_main_roman;
      color: $blue;
      margin: 20px 0;
      padding: 0 20px;

      > a,
      > button:not(.tag-dropdown),
      .datepicker-wrap {
        display: none;
      }

      &.show {
        padding-bottom: 10px;

        > a,
        > button:not(.tag-dropdown),
        .datepicker-wrap {
          display: block;
        }

        button.tag-filter {
          width: 100%;
        }
      }
    }
  }
}

.tag-container__check {
  a:focus,
  button:focus {
    outline: 3px solid #00a5e3;
    transition: none;
    background: $red;
    color: $white;
  }

  .btn-date-picker {
    display: inline-block;
    margin-right: 10px;
    border-radius: 50px;
    background-color: $white;
    border: 1px solid $gray__xl;
    color: $blue;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: rem(14);
    font-family: $font_main_roman;
    padding: 6px 20px 5px;
    position: relative;
    vertical-align: top;
    @include transition(all 0.5s ease);

    span {
      color: $gray__xd;
      font-size: rem(22);
      line-height: 0.5;
      vertical-align: middle;
      margin-left: 5px;
    }

    &:not(.active):hover {
      background-color: $trueblack__xl;
      color: $gray__xd;
      border-color: $gray__xl;
    }

    &.active {
      background: $red;
      border-color: $red;
      color: $white;

      span {
        color: $white;
      }
    }

    &:focus,
    &:visited {
      span {
        color: $white;
      }
    }
  }
}

.tag__vertical {
  .btn {
    float: left;
    clear: both;
  }
}

.tag-dropdown,
button.tag-dropdown {
  padding: 7px 0;
  cursor: pointer;
  width: 100%;
  border-radius: 0 !important;
  border: none;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;

  @media #{$tablet_min} {
    display: none;
  }

  &.open {
    margin-bottom: 10px;

    .fa:before {
      content: "\f106";
    }
  }

  .fa {
    float: right;
    font-size: rem(20);
    color: $gray__xd;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: $white;
    color: $blue;
  }
}

/* TABS */

.tabs--vertical {
  border: 1px solid $gray__xl;
  padding: 45px 0;

  @media #{$tablet} {
    padding: 35px 0;
  }

  @media #{$mobile_sm_max} {
    border: none;
    padding: 0;
  }

  .card-soup {
    width: 100%;
  }

  .card {
    border: 1px solid $gray__xl;
    border-radius: 0;
    margin-bottom: 10px;
  }

  .card-header {
    background: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
  }
  .card-title {
    margin: 0;
    font-size: rem(16);
    font-family: $font_main_roman;
  }

  .nav-link {
    padding: 11px 20px;
    border-radius: 0;
    border: none;
    transition: none;

    @media #{$mobile_sm_max} {
      &:after {
        content: "\f107";
        font-family: "Font Awesome 6 Pro";
        font-size: rem(19);
        font-weight: 900;
        color: $gray__xd;
        position: absolute;
        right: 7px;
        top: 10px;
      }

      &[aria-expanded="true"] {
        font-family: $font__main;
        font-weight: bold;
        color: $red;
        box-shadow: 3px 0 0px $red inset;

        &:after {
          content: "\f106";
        }
      }
    }
  }
}

.tabs--vertical__tabs {
  float: left;
  flex-wrap: wrap;
  border: none;
  flex-direction: column;

  @media #{$mobile_mini} {
    width: 220px;
  }

  .nav-item {
    width: 100%;
    font-size: rem(20);
    font-family: $font_main_roman;
    line-height: 1;
    margin-bottom: 10px;

    @media #{$tablet} {
      font-size: rem(16);
    }
  }

  .nav-link {
    padding: 8px 35px;
    border-radius: 0;
    border: none;
    position: relative;

    @media #{$mobile} {
      padding: 8px 25px;
    }

    &:hover,
    &:focus {
      color: $blue;
      box-shadow: 4px 0 0px $blue inset;
    }

    &.active {
      font-family: $font__main;
      font-weight: bold;
      color: $red;
      box-shadow: 4px 0 0px $red inset;
    }

    &:visited {
      color: $blue;
    }
  }
}

.tabs--vertical__tab-content {
  float: left;
  width: calc(100% - 330px);
  padding-left: 20px;

  @media #{$tablet} {
    width: calc(100% - 230px);
  }

  @media #{$mobile} {
    width: 100%;
    padding-left: 0;
  }
}

.no-js .tab-pane {
  display: block;
}

.tabs--horizotal__tab-pane,
.tabs--vertical__tab-pane {
  font-size: rem(20);
  font-family: $font_main_roman;
  line-height: 1.3;

  @media #{$tablet} {
    font-size: rem(16);
    line-height: 1.5;
  }

  @media #{$mobile} {
    padding: 20px;
  }

  @media #{$mobile_mini} {
    padding: 0 20px;
  }

  &:not(.table) a:not(.btn) {
    border-bottom: 1px solid;
    text-decoration: none;
    @include transition(color 0.5s ease);

    &:hover {
      text-decoration: underline;
      border-bottom: 1px solid transparent;
    }
  }

  h1 {
    font-size: rem(26);
    font-family: $font__main;
    font-weight: bold;
    letter-spacing: -1px;
  }

  h2 {
    font-size: rem(26);
    font-family: $font__main;
    font-weight: bold;
  }

  h3 {
    font-size: rem(20);
    font-family: $font__main;
    font-weight: bold;
    line-height: 1.3;
    margin: 0 0 5px;

    @media #{$tablet} {
      font-size: rem(16);
    }
  }

  ul {
    margin: 10px 0;

    li {
      position: relative;
      padding-left: 20px;
      margin-bottom: 10px;

      &:before {
        content: "\002022";
        color: $red;
        left: 0;
        position: absolute;
        top: 4px;
        font-size: rem(15);
      }
    }
  }

  ol {
    margin: 10px 0;

    ul {
      padding-left: 10px;
    }

    li {
      margin: 10px 0;
    }
  }
}

.tabs--horizotal {
  border: 1px solid $gray__xl;
  padding: 0 45px;

  @media #{$mobile} {
    padding: 0 20px;
  }

  @media #{$mobile_sm_max} {
    border: none;
    padding: 0;
  }

  .card-soup {
    width: 100%;
  }

  .card {
    border: 1px solid $gray__xl;
    border-radius: 0;
    margin-bottom: 10px;
  }

  .card-header {
    background: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
  }

  .card-title {
    margin: 0;
    font-size: rem(16);
    font-family: $font_main_roman;
  }

  .nav-link {
    padding: 11px 20px;
    border-radius: 0;
    border: none;
    transition: none;

    @media #{$mobile_sm_max} {
      &:after {
        content: "\f107";
        font-family: "Font Awesome 6 Pro";
        font-size: rem(19);
        font-weight: 900;
        color: $gray__xd;
        position: absolute;
        right: 7px;
        top: 10px;
      }

      &[aria-expanded="true"] {
        font-family: $font__main;
        font-weight: bold;
        color: $red;
        box-shadow: 0 4px 0 $red inset;

        &:after {
          content: "\f106";
        }
      }
    }
  }
}

.tabs--horizotal__tabs {
  border-bottom: none;

  .nav-item {
    font-size: rem(20);
    font-family: $font_main_roman;
    line-height: 1;
    margin-bottom: 10px;

    @media #{$tablet} {
      font-size: rem(16);
    }

    + .nav-item {
      margin-left: 38px;

      @media #{$mobile} {
        margin-left: 2.8%;
      }
    }
  }

  .nav-link {
    padding: 18px 0;
    border-radius: 0;
    border: none;
    position: relative;

    @media #{$mobile} {
      padding: 15px 0;
    }

    &:hover,
    &:focus {
      color: $blue;
      box-shadow: 0 4px 0 $blue inset;
    }

    &.active {
      font-family: $font__main;
      font-weight: bold;
      color: $red;
      box-shadow: 0 4px 0 $red inset;
    }

    &:visited {
      color: $blue;
    }
  }
}

.tabs--horizotal__tab-pane {
  padding: 20px 0;

  @media #{$mobile_sm_max} {
    padding: 20px;
  }
}

/* Accordion */
.accordion {
  .card {
    border-radius: 0;
    border: none;
    margin-bottom: 8px;
  }

  .card-header {
    border-radius: 0;
    background: transparent;
    padding: 0;
    border: none;
  }

  .card-header-content {
    background: $gray__xl;
    padding: 3px;
  }

  &.accordion__left .card-header .btn,
  &.accordion__left .card-header .btn h1,
  &.accordion__left .card-header .btn h2,
  &.accordion__left .card-header .btn h3,
  &.accordion__left .card-header .btn h4,
  &.accordion__left .card-header .btn h5,
  &.accordion__left .card-header .btn h6 {
    text-align: left;
    padding-right: 36px;
    position: relative;

    span {
      float: right;
      margin-top: 0;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      width: 30px;
    }
  }

  .card-header .btn,
  .card-header .btn h1,
  .card-header .btn h2,
  .card-header .btn h3,
  .card-header .btn h4,
  .card-header .btn h5,
  .card-header .btn h6 {
    border: none;
    display: block;
    padding: 12px 20px 15px;
    text-align: center;
    font-size: rem(20);
    font-weight: bold;
    color: $gray__xd;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      padding: 0;
      letter-spacing: normal;

      @media #{$mobile} {
        font-size: rem(16);
      }
    }

    @media #{$mobile} {
      font-size: rem(16);
    }

    span {
      display: block;
      line-height: 7px;
      margin-top: 5px;
      font-size: rem(20);
    }

    &:hover {
      color: $blue;
    }

    &:visited {
      color: $gray__xd;
      &:hover {
        color: $blue;
      }
    }

    &[aria-expanded="true"] .fa:before {
      content: "\f106";
    }
  }

  .card-body {
    border: 1px solid $gray__xl;

    font-size: rem(20);
    font-family: $font_main_roman;
    line-height: 1.3;

    @media #{$tablet} {
      font-size: rem(16);
      line-height: 1.5;
    }
  }
}

/* Accordion and Tab copy_link */
.tag-target {
  .copy_text-container {
    display: none;
  }
}

ol:not(.breadcrumb):not(.carousel-indicators) {
  counter-reset: item;

  > li {
    display: block;
    position: relative;
    padding-left: 30px;

    &:before {
      content: counter(item) ". ";
      counter-increment: item;
      color: $red;
      position: absolute;
      left: 0;

      .bg-primary &,
      .bg-secondary &,
      .bg-cool-gray & {
        color: $white;
      }
    }
  }
}

.tick-list {
  margin: 22px 0;

  .container-small & {
    margin-bottom: 0;
  }

  li {
    position: relative;
    padding-left: 30px;
    font-size: rem(20);
    font-family: $font_main_roman;
    line-height: 1.5;

    @media #{$tablet} {
      font-size: rem(16);
    }

    &:before {
      content: "\e90c";
      font-family: "uwg-icon";
      position: absolute;
      font-size: rem(16);
      left: 0;
      top: 4px;
    }
  }
}

.custom-checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding-left: 40px;

    @media #{$mobile} {
      padding-left: 30px;
    }
  }

  & + label:before {
    content: "";
    margin-right: 20px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: $gray__xl;
    border: 2px solid $trueblack__xl;
    position: absolute;
    top: 1px;
    left: 0;

    @media #{$mobile} {
      width: 18px;
      height: 18px;
    }
  }

  &:hover + label:before {
    background: #fefefe;
  }

  &:focus + label:before,
  &:checked + label:before {
    border-color: $gray__xd;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: "\e90c";
    font-family: "uwg-icon";
    position: absolute;
    left: 4px;
    top: 2px;
    color: $gray__xd;
    font-size: rem(12);
    font-weight: bold;

    @media #{$mobile} {
      left: 3px;
      font-size: rem(11);
    }
  }
}

.widget--alert {
  border: 1px solid $gray__xl;
  padding: 30px 40px 30px 100px;
  position: relative;
  margin-bottom: 30px;
  font-weight: normal;

  a {
    @include transition(color 0.5s ease);
    text-decoration: none;
    border-bottom: 1px solid;
    &:hover,
    &:focus {
      text-decoration: underline;
      border-bottom: 1px solid transparent;
    }
  }

  @media #{$tablet} {
    font-size: rem(15);
    padding: 25px 25px 25px 80px;
  }

  @media #{$mobile} {
    font-size: rem(13);
    padding: 20px 20px 20px 60px;
  }

  [class*="icon-"] {
    position: absolute;
    font-size: rem(32);
    color: $gray__xd;
    top: 35px;
    left: 40px;

    @media #{$tablet} {
      font-size: rem(30);
      left: 22px;
      top: 30px;
    }

    @media #{$mobile} {
      font-size: rem(27);
      left: 17px;
      top: 26px;
    }
  }
}

.jumbotron-content__bottom {
  .jumbotron__image-wrap {
    height: 510px;
    overflow: hidden;

    @media #{$tablet} {
      height: 410px;
    }

    @media #{$mobile} {
      height: 205px;
    }
  }

  .jumbotron__content {
    width: 100%;
  }

  .content-col {
    color: $white;
    padding: 35px;

    @media #{$mobile} {
      padding: 32px 30px;
    }

    h2 {
      font-size: rem(38);
      font-family: $font_main_bdCon;
      line-height: 1;
      text-transform: uppercase;
      letter-spacing: -1px;

      @media #{$tablet} {
        font-size: rem(30);
      }
    }

    .listing-date {
      font-family: $font_main_bdCon;
      font-size: rem(30);
      line-height: 0.9;
      color: $gray__xl;
      letter-spacing: -1px;
      margin-bottom: 18px;

      @media #{$tablet} {
        font-size: rem(24);
      }
    }

    .read-more {
      color: $white;
      float: right;
      font-weight: bold;
      position: relative;

      @media #{$tablet} {
        font-size: rem(13);
      }

      &:hover,
      &:visited {
        text-decoration: underline;
      }
    }
  }

  .jumbotron__image-wrap {
    img {
      width: 100%;
    }
  }
}

.banner-with-sidebar {
  margin-bottom: 45px;

  @media #{$tablet} {
    margin-bottom: 40px;
  }

  @media #{$mobile} {
    margin-bottom: 24px;
    .left-col,
    .right-col {
      width: 100%;
    }
  }

  @media #{$desktop} {
    .left-col {
      width: 45%;
    }
    .right-col {
      width: 55%;
    }
  }

  @media #{$tablet_only} {
    .left-col,
    .right-col {
      width: 50%;
    }
  }
}

/*News*/
.jumbotron-gradient__black-left {
  a:not(.btn),
  a:visited:not(.btn) {
    color: $white;
    text-decoration: none;
    border-bottom: 1px solid;

    &:hover:not(.btn),
    &:focus:not(.btn) {
      color: $white;
      text-decoration: underline;
      border-bottom: 1px solid transparent;
    }
  }

  .jumbotron-gradient__image {
    height: 550px;
    min-height: 550px;
    width: 100%;
    position: relative;

    &.image-container {
      background-position: left center;

      &.parallax-slider {
        background-position: center;
        background-size: cover;
      }
    }

    &:before {
      content: "";
      @include absolute-cover();
      top: 0;
      @include linear-gradient(
        to right,
        rgba(42, 44, 47, 0.95) 0%,
        rgba(42, 44, 47, 0.95) 48%,
        rgba(42, 44, 47, 0) 100%
      );
      width: 60%;
      z-index: 101;
    }

    @media #{$tablet} {
      height: 440px;
      min-height: 440px;

      &:before {
        width: 70%;
      }
    }

    @media #{$mobile} {
      height: 206px;
      min-height: 206px;

      &:before {
        display: none;
      }
    }

    .container-small & {
      height: 206px;
      min-height: 206px;

      &:before {
        display: none;
      }
    }
  }
}

.jumbotron-gradient__light-left {
  .jumbotron-gradient__image {
    height: 550px;
    min-height: 550px;
    width: 100%;
    position: relative;

    &.image-container {
      background-position: left center;

      &.parallax-slider {
        background-position: center;
        background-size: cover;
      }
    }

    &:before {
      content: "";
      @include absolute-cover();
      top: 0;
      @include linear-gradient(
        to right,
        rgba(255, 255, 255, 0.95) 0%,
        rgba(255, 255, 255, 0.85) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      width: 55%;
      z-index: 101;
    }

    @media #{$tablet} {
      height: 440px;
      min-height: 440px;

      &:before {
        width: 70%;
      }
    }

    @media #{$mobile} {
      height: 206px;
      min-height: 206px;

      &:before {
        display: none;
      }
    }

    .container-small & {
      height: 206px;
      min-height: 206px;

      &:before {
        display: none;
      }
    }
  }
}

.jumbotron_left--sidebar {
  color: $white;

  .jumbotron-gradient__light-left & {
    color: $cool-gray;
  }

  @media #{$mobile} {
    background: $gray__xd;
    padding: 20px 20px 30px;
    width: 100%;

    .jumbotron-gradient__light-left & {
      background: $white;
    }
  }

  @media #{$tablet_min} {
    position: absolute;
    left: 37px;
    top: 40px;
    max-width: 330px;
    z-index: 102;
  }

  @media #{$desktop} {
    left: 88px;
    top: 48px;
    max-width: 400px;
  }

  .container-small & {
    background: $gray__xd;
    padding: 20px 20px 30px;
    width: 100%;
    position: relative;
    left: auto;
    top: auto;
    max-width: 100%;

    .btn {
      font-size: rem(15);
    }
  }

  h2 {
    font-family: $font__helveticaBlkCon;
    font-size: rem(50);
    letter-spacing: 1px;
    line-height: 0.9;
    text-shadow: 0 1px 0 $gray__xd;

    @media #{$tablet} {
      font-size: rem(40);
    }

    .container-small & {
      font-size: rem(40);
    }

    .jumbotron-gradient__light-left & {
      color: $red;
    }
  }
}

.filter__horizontal {
  padding: 10px 0 0;
  @media #{$mobile} {
    padding: 0;
  }
}

.filter__horizontal-gallery {
  margin-top: 40px;
  overflow: hidden;
  background: $white;

  @media #{$tablet} {
    margin-top: 30px;
  }

  @media #{$mobile} {
    margin-top: 20px;
  }

  &.masonry-gallery .grid-item {
    padding: 0;
    margin-bottom: 25px;

    @media #{$tablet} {
      margin-bottom: 20px;
    }
  }
}

.filter__horizontal-item {
  border: 1px solid $gray__xl;
  margin-bottom: 25px;
  position: relative;
  width: 100%;

  figure {
    margin: 0;
    height: 100%;
  }

  @media #{$tablet} {
    margin-bottom: 20px;
  }
}

.filter__horizontal-content {
  padding: 40px 20px 65px 42px;
  max-width: 95%;

  @media #{$tablet} {
    padding: 32px 20px 50px 32px;
    max-width: 100%;
  }

  @media #{$mobile} {
    padding: 16px;
  }

  h3 {
    font-size: rem(25);
    font-weight: bold;
    color: $blue;
    margin-bottom: 16px;

    a {
      color: $blue;
      display: inline-block;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      &:visited {
        color: $red;
      }
    }

    @media #{$tablet} {
      font-size: rem(20);
    }

    @media #{$mobile} {
      font-size: rem(18);
    }
  }

  p {
    font-size: rem(16);
    font-family: $font_main_roman;
    margin: 10px 0;

    @media #{$tablet} {
      font-size: rem(13);
    }

    @media #{$mobile} {
      display: none;
    }
  }

  .listing-date {
    font-size: rem(14);
    font-family: $font_main_roman;

    @media #{$tablet_min} {
      position: absolute;
      bottom: 30px;
    }

    @media #{$tablet} {
      bottom: 25px;
    }

    @media #{$tablet} {
      font-size: rem(12);
      margin-top: 8px;
    }
  }

  [class*="bg-"] & {
    max-width: 67%;

    @media #{$tablet} {
      max-width: 80%;
    }

    @media #{$mobile} {
      max-width: 100%;
    }
  }
}

.blockquote {
  padding: 20px 0;

  p {
    font-size: rem(38);
    font-family: $font_main_con;
    line-height: 1;
    letter-spacing: -1px;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 0;

    @media #{$tablet} {
      font-size: rem(30);
    }
  }

  @media #{$mobile} {
    padding: 15px 0;
  }
}

.blockquote-footer {
  font-size: rem(20);
  font-weight: bold;
  font-family: $font_main_roman;
  color: $gray__xd;

  &:before {
    content: "";
  }

  @media #{$mobile} {
    font-size: rem(16);
  }
}

.blockquote__red p {
  color: $red;
}

.blockquote__blue p {
  color: $blue;
}

.jumbotron__video {
  padding: 0;
  min-height: 340px;

  .video-container {
    width: 100%;
    height: 100%;
    object-fit: cover;

    .video-js:not(.vjs-has-started) .vjs-control-bar,
    .video-js.vjs-ended .vjs-control-bar,
    .video-js.vjs-paused .vjs-control-bar {
      display: none;
    }

    .video-js {
      &.video-with-content.w-100 {
        @media #{$tablet} {
          height: 0;
          padding-top: 56.3%;
        }
      }
    }
  }

  @media #{$tablet_lg} {
    min-height: 255px;
    height: 100%;
  }

  @media #{$mobile} {
    min-height: 50px;
    height: auto;
    margin: 0 -15px;

    .component-container & {
      margin: 0;
    }
  }

  .overlay {
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 56.25%;
    z-index: 1;

    &:before {
      content: "";
      background: rgba($oil__xd, 0.65);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      bottom: 0;
      z-index: 1;
    }

    &.hide {
      background: none;
      z-index: -1;
    }
  }

  a.video-play {
    position: absolute;
    left: 50%;
    top: 50%;
    color: $white;
    font-size: rem(60);
    text-shadow: 0 1px 0 rgba($trueblack, 0.5);
    transform: translate(-50%, -50%);
    z-index: 2;

    @media #{$tablet} {
      font-size: rem(50);
    }

    @media #{$mobile} {
      font-size: rem(40);
    }

    &:hover {
      color: $gray__xl;
      opacity: 0.8;
    }

    &.play {
      .icon-play:before {
        content: "\e90f";
      }
    }
  }
}

.uwg-video {
  background: $oil;
  width: 100%;
  object-fit: cover;
}

.jumbotron__video-content {
  width: 100%;
  transition: opacity 0.5s, visibility 0.5s;

  @media #{$tablet_min} {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 62px 0 32px 62px;
    width: 100%;
    z-index: 1;

    .jumbotron-fluid & {
      width: 100%;
      padding: 0 0 54px;
    }
  }

  @media #{$desktop} {
    padding: 62px;
    width: 100%;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;

    @media #{$mobile} {
      height: 0;
    }
  }

  .container {
    position: relative;
  }
}

.container-sm {
  max-width: 905px;
  margin: 0 auto;

  @media #{$tablet} {
    padding: 0 15px;
    max-width: 740px;
  }

  @media #{$mobile} {
    max-width: 100%;
  }
}

.uwg-content-section {
  .special-heading {
    @media #{$tablet} {
      margin-bottom: 20px;
    }

    @media #{$mobile} {
      margin-bottom: 10px;
    }
  }

  h2:not(.special-heading) {
    font-family: $font__main;
    font-size: rem(25);
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 15px;

    @media #{$tablet} {
      font-size: rem(20);
    }
  }

  h3 {
    font-family: $font__main;
    font-size: rem(22);
    font-weight: bold;
    letter-spacing: -0.88px;
    line-height: 1.3;
    margin-bottom: 15px;

    @media #{$tablet} {
      font-size: rem(18);
    }
  }

  h4 {
    font-size: rem(20);
    line-height: 1.2;
    color: $gray__l;

    @media #{$tablet} {
      font-size: rem(16);
    }
  }

  h5 {
    font-size: rem(18);
    line-height: 1.5;
    letter-spacing: -0.65px;
    margin-bottom: 5px;

    @media #{$tablet} {
      font-size: rem(14);
    }
  }

  p {
    margin-top: 10px;

    @media #{$tablet} {
      font-size: rem(16);
    }
    a {
      text-decoration: underline;

      &:hover {
        color: $blue;
        text-decoration: none;
      }
    }
  }
}

.mw-770 {
  max-width: 770px;
  margin: 0 auto;

  @media #{$tablet} {
    max-width: 620px;
  }

  @media #{$mobile} {
    max-width: 100%;
  }
}

.uwg-event__item {
  border: 1px solid $gray__xl;
  margin-top: 50px;

  img {
    max-height: 300px;
  }

  @media #{$mobile} {
    margin-top: 15px;
  }
}

.uwg-event__item-content {
  padding: 5px 25px 25px;

  @media #{$tablet} {
    padding: 0 20px 20px;
  }

  .image-container {
    height: 260px;
  }

  h3 {
    font-size: rem(25);
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 10px;
    letter-spacing: 0;

    @media #{$tablet} {
      font-size: rem(19);
    }

    a {
      display: inline-block;

      &:hover {
        color: $blue;
        text-decoration: underline;
      }
    }
  }

  p {
    font-size: rem(16);
    font-family: $font_main_roman;
    margin-bottom: 5px;

    @media #{$tablet} {
      font-size: rem(13);
    }
  }
}

.text-blue {
  color: $blue;
}

.extra-link-content {
  padding: 70px 0;
  text-align: center;

  @media #{$tablet} {
    padding: 45px 0;
  }

  h2 {
    font-size: rem(36);
    font-weight: bold;
    line-height: 1.25;

    @media #{$tablet} {
      font-size: rem(28);
    }

    a {
      font-style: italic;
      display: inline-block;
      padding-right: 4px;
      text-decoration: none;
      border-bottom: 3px solid;
      transition: color 0.5s ease;

      &:hover,
      &:focus {
        color: $blue;
        text-decoration: underline;
        border-bottom: 3px solid transparent;
      }
    }
  }
}

.blog-list-item {
  margin-bottom: 45px;

  @media #{$tablet} {
    margin-bottom: 25px;
  }

  h3 {
    font-size: rem(25);
    font-weight: bold;
    line-height: 1.2;

    @media #{$tablet} {
      font-size: rem(19);
    }
  }

  p {
    font-size: rem(16);
    font-family: $font_main_roman;
    margin-bottom: 0;

    @media #{$tablet} {
      font-size: rem(13);
    }
  }
  a {
    font-family: $font_main_roman;
    text-decoration: none;
    border-bottom: 1px solid;
    transition: color 0.5s ease;

    &:hover,
    &:focus {
      color: $blue;
      text-decoration: underline;
      border-bottom: 1px solid transparent;
    }

    @media #{$tablet} {
      font-size: rem(13);
    }
  }
}

/* Alert Box */
.alert,
.alert-box {
  border-radius: 0;
  border: 2px solid transparent;
  padding: 25px 30px;

  p {
    font-size: rem(18);
    line-height: 1.25;
    margin-bottom: 5px;

    &.h2 {
      font-size: rem(25);
      line-height: 1.2;
      margin-bottom: 15px;

      @media #{$mobile} {
        font-size: rem(20);
      }
    }
  }

  h3 {
    font-size: rem(28);
    font-weight: bold;
  }

  a {
    text-decoration: none;
    border-bottom: 1px solid;

    &:hover,
    &:focus {
      text-decoration: underline;
      border-bottom: 1px solid transparent;
    }
  }

  .close {
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: rem(16);
    opacity: 1;
    text-shadow: none;
    padding: 0;
    text-decoration: none;
    border-bottom: none;

    &:hover,
    &:focus {
      opacity: 0.8;
      text-decoration: none;
      border-bottom: none;
    }
  }
}

.alert-danger {
  background: $white;
  border-color: $red;
  color: $gray__xd;
}

.alert-success {
  background: $white;
  border-color: $green;
  color: $gray__xd;
}

.alert-warning {
  background: $white;
  border-color: $gold;
  color: $gray__xd;
}

.alert-dark {
  color: $white;
  background-color: $gray__xd;
  border-color: $gray__xd;

  .alert-overlay & {
    background: $white;
    color: $gray__xd;
  }

  .close {
    color: $white;
  }
}

.alert-annoucement {
  background: $white;
  border-color: $gray__xd;
  color: $gray__xd;
}

.alert-sm {
  max-width: 300px;
}

.alert-overlay {
  background: $gray__xd;
  padding: 5% 0;
}

/* Audio Player */
.audio-wrap {
  border: 2px solid $gray__xd;
  padding: 27px;

  h2 {
    font-size: rem(28);
    font-weight: bold;
    line-height: 1.2;
  }

  p {
    font-size: rem(18);
    line-height: 1.25;
    margin-bottom: 10px;
  }

  a:not(.audio-play-btn) {
    font-size: rem(16);
    font-family: $font_main_roman;
    &:hover {
      color: $blue;
      text-decoration: underline;
    }
  }
}

.audio-play-btn {
  font-size: rem(58);

  &.paused {
    color: $red;
    .icon-play:before {
      content: "\e90f";
    }
  }
}

/* Progress Bar */
.progress-wrap {
  overflow: hidden;

  h2 {
    font-size: rem(28);
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 20px;
  }
}

.progress {
  background: $trueblack__xl;
  border-radius: 20px;
  overflow: visible;
}

.progress-bar {
  background: $red;
  position: relative;
  border-radius: 15px;

  .blue & {
    background: $blue;
  }
}

.progress-text {
  font-size: rem(14);
  font-family: $font_main_roman;
  margin-bottom: 10px;
  display: block;
  padding-top: 5px;
}

/*Circle progress Bar*/
.progress-content {
  position: relative;
  z-index: 1;
}

.progress-bar-circle-blue {
  background-color: $blue;
}

.progress-bar-circle-red {
  background-color: $red;
}

.progress-bar-circle-grey-dark {
  background-color: $gray__xd;
}

.progress-bar-circle-grey {
  background-color: $gray__l;
}

.progress-bar-circle-grey-light {
  background-color: $gray__xl;
}

.progress-bar-circle {
  position: relative;
  text-align: center;
  width: 220px;
  height: 220px;
  border-radius: 100%;
  background-color: #d9192c;
  background-image: linear-gradient(91deg, transparent 50%, #ddd 50%),
    linear-gradient(90deg, #ddd 50%, transparent 50%);
  transform: rotate(-130deg);
  margin: 0 auto;

  .progress-bar-circle-blue &,
  .progress-bar-circle-red &,
  .progress-bar-circle-grey-dark &,
  .progress-bar-circle-grey & {
    .progress-circle-stat,
    .text,
    .goal-text {
      color: $white;
    }
  }

  .progress-bar-circle-blue & {
    .progress-circle-inner,
    .goal-bg {
      background-color: $blue;
    }
  }

  .progress-bar-circle-red & {
    background-color: $blue;

    .progress-circle-inner,
    .goal-bg {
      background-color: $red;
    }
  }

  .progress-bar-circle-grey-dark & {
    .progress-circle-inner,
    .goal-bg {
      background-color: $gray__xd;
    }
  }

  .progress-bar-circle-grey & {
    .progress-circle-inner,
    .goal-bg {
      background-color: $gray__l;
    }
  }

  .progress-bar-circle-grey-light & {
    .progress-circle-inner,
    .goal-bg {
      background-color: $gray__xl;
    }
  }
}

.progress-circle-inner {
  position: relative;
  top: 20px;
  left: 20px;
  text-align: center;
  width: 180px;
  height: 180px;
  border-radius: 100%;
  background-color: #fff;
  transform: rotate(130deg);

  div {
    position: relative;
    padding-top: 70px;
  }

  .text {
    color: $red;
    font-family: $font_main_roman;
    font-size: rem(20);
    letter-spacing: -1px;

    .bg-secondary & {
      color: $white;
    }
  }

  .goal-text {
    font-size: rem(22);
    font-weight: bold;
    padding-bottom: 22px;
    padding-top: 8px;
    display: block;
    letter-spacing: -0.06em;
    overflow: hidden;
    position: relative;
    min-height: 70px;
  }

  .goal-bg {
    background: $white;
    width: 180px;
    height: 180px;
    display: block;
    position: absolute;
    left: 0;
    top: -27px;
    transform: rotate(-45deg);
    z-index: -1;
  }
}

.progress-circle-stat {
  line-height: 1;
  font-family: $font_main_hvCon;
  font-size: rem(37);
  letter-spacing: -1px;
  color: $red;

  .bg-secondary & {
    color: $white;
  }
}

/* Video Player */
.video-container {
  width: 640px;
  position: relative;
  overflow: hidden;
  color: $gray__xd;

  .jumbotron-fluid &,
  .modal-body & {
    width: 100%;
  }

  @media #{$mobile} {
    width: 100%;
  }

  .video-js {
    font-size: rem(10);
    color: $gray__xd;
    background: $white;

    .isIE & {
      padding-top: 56.5%;
    }

    &.vjs-youtube.vjs-fluid {
      padding-top: 56.3%;
    }

    video {
      width: 100%;
    }

    &.vjs-auto.vjs-youtube.vjs-paused:not(.vjs-has-started) {
      .vjs-big-play-button {
        display: none !important;
      }
    }

    .vjs-control-bar,
    .vjs-menu-button .vjs-menu-content {
      background-color: $gray__xxl;
    }

    .vjs-poster {
      background-size: cover;
    }

    .vjs-big-play-button {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      line-height: 1.9;
      position: absolute;
    }

    .vjs-poster:before {
      content: "";
      background: rgba(39, 41, 47, 0.65);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .vjs-control-bar {
      display: flex;
      visibility: visible;
      opacity: 1;
      transition: visibility 0.1s, opacity 0.1s;
      padding: 3px;
      height: 35px;
      z-index: 10;

      .vjs-button:hover {
        opacity: 0.8;
      }
    }

    .vjs-play-control {
      margin-left: 3px;
    }

    .vjs-slider {
      background: $trueblack__l;
    }

    .vjs-volume-panel {
      display: block;
      position: absolute;
      right: 40px;
      width: 100px;
      top: 3px;
      height: 29px;

      &:hover {
        width: 100px;
      }

      .vjs-mute-control {
        float: left;
      }

      .vjs-volume-control {
        width: 5em;
        height: 3em;
        opacity: 1;
        visibility: visible;
      }
    }

    .vjs-playback-rate,
    .vjs-chapters-button,
    .vjs-descriptions-button,
    .vjs-audio-button {
      display: none;
    }

    .vjs-volume-level {
      background: $red;

      &:before {
        font-size: rem(12);
        color: $red;
        top: -4px;
      }
    }

    .vjs-current-time {
      display: flex;
      padding: 0 6px;
    }

    .vjs-current-time-display {
      font-size: rem(11);
      color: $gray__xd;
    }

    .vjs-subs-caps-button.vjs-control {
      position: absolute;
      right: 138px;
      height: 30px;
      padding: 0;
      width: 30px;

      .vjs-icon-placeholder:before {
        content: "CC";
        font-size: rem(15);
        font-family: $font__main;
        font-weight: bold;
        line-height: 2;
      }
    }

    .vjs-progress-control {
      width: 100px;
      min-width: 20px;
      flex: inherit;

      @media #{$mobile_sm} {
        width: 80px;
      }

      .vjs-progress-holder {
        background: $trueblack__l;
      }

      &:hover {
        .vjs-progress-holder {
          font-size: rem(10);
        }

        .vjs-time-tooltip {
          display: none;
        }
      }
    }

    .vjs-load-progress {
      background: $trueblack__l;
      div {
        background: $trueblack__l;
      }
    }

    .vjs-play-progress {
      background: $gray__xd;

      &:before {
        color: $red;
        font-size: rem(12);
        top: -5px;
      }
    }

    .vjs-remaining-time {
      display: none;
    }

    .vjs-fullscreen-control {
      position: absolute;
      right: 3px;
      height: 28px;
    }
  }

  .vjs-default-skin.vjs-playing .vjs-big-play-button {
    display: none;
  }

  .vjs-default-skin.vjs-playing.vjs-user-active .vjs-big-play-button {
    display: block !important;

    span:before {
      content: "\f103";
    }
  }

  .vjs-default-skin.vjs-paused:not(.vjs-scrubbing) {
    .vjs-big-play-button {
      display: block !important;

      span:before {
        content: "\f101";
      }
    }

    .vjs-poster {
      display: block;
    }
  }

  .vjs-button > .vjs-icon-placeholder:before {
    font-size: rem(25);
    line-height: 1.25;
  }
}
.video-caption {
  font-size: rem(25);
  font-weight: bold;
  line-height: 30px;
  margin-top: 20px;

  @media #{$mobile} {
    font-size: rem(20);
  }
}

.video-popup {
  position: relative;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay {
    background: $gray__xd;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .video-container {
    width: 100%;
    max-width: 740px;
  }

  .video-caption {
    color: $white;
  }
}

/* Pagination */
.pagination {
  align-items: center;

  li + li {
    margin-left: 3px;
  }

  .page-item:last-child {
    margin-right: 10px;
  }

  .page-item.active .page-link:not(.page-nav):not(.page-prev):not(.page-next) {
    background: $red;
    color: $white;
  }

  .page-link {
    border: none;
    border-radius: 50%;
    color: $blue;
    font-size: rem(20);
    font-weight: bold;
    font-family: $font__main;
    margin-left: 0;
    padding: 6px 11.5px 8px;
    line-height: 1;

    @media #{$mobile} {
      font-size: rem(16);
    }

    &:hover {
      background: $trueblack__xl;
      color: $gray__xd;
    }

    &.page-nav {
      padding: 0;
      border-radius: 0;

      &:hover {
        background: none;
        color: $blue;
        text-decoration: underline;
      }
    }

    &.page-prev,
    &.page-next {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      text-align: center;
      border: 2px solid $blue;
      font-size: rem(22);
      margin-left: 15px;
      margin-right: 15px;
      padding: 0;
      line-height: 1.3;

      @media #{$mobile} {
        margin-left: 10px;
        margin-right: 10px;
        font-size: rem(20);
      }

      span {
        line-height: 1;
        font-weight: bold;
      }

      &:hover {
        background: $gray__xd;
        border-color: $gray__xd;
        color: $white;
      }
    }
  }
}

/* Datepicker */
.hasDatepicker {
  .ui-datepicker {
    border-radius: 0;

    th {
      font-weight: 300;
      text-transform: uppercase;
      font-size: rem(10);
    }

    td .ui-state-default {
      border: 1px solid transparent;
      background: transparent;
      color: $oil__d;
      text-align: center;
      font-weight: 300;
      font-size: rem(12);

      &.ui-state-highlight {
        border: 1px solid transparent;
        background: transparent;
        color: $blue;
        font-weight: bold;
      }

      &.ui-state-hover:not(.ui-state-highlight) {
        border-color: $red;
        background: transparent;
        color: $red;
        font-weight: bold;
      }
    }
  }

  .ui-widget.ui-widget-content {
    border: none;
  }

  .ui-widget-header {
    background: transparent;
    border-radius: 0;
    border: none;
    color: $gray__xd;

    .ui-icon {
      background: none;
    }

    .ui-datepicker-prev:before {
      content: "\f104";
      font-family: "Font Awesome 6 Pro";
      font-size: rem(20);
    }

    .ui-datepicker-next:before {
      content: "\f105";
      font-family: "Font Awesome 6 Pro";
      font-size: rem(20);
      display: block;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
      text-align: center;
      cursor: pointer;
    }

    .ui-datepicker-prev-hover {
      left: 2px;
      top: 2px;
      color: $red !important;
    }

    .ui-datepicker-next-hover {
      right: 2px;
      top: 2px;
      color: $red !important;
    }

    .ui-state-hover,
    .ui-widget-content .ui-state-hover,
    .ui-widget-header .ui-state-hover,
    .ui-state-focus,
    .ui-widget-content .ui-state-focus,
    .ui-widget-header .ui-state-focus,
    .ui-button:hover,
    .ui-button:focus {
      border: none;
      background: transparent;
      font-weight: 600;
      color: $red;
    }
  }
}

.component-container {
  a:not(.btn):visited,
  .masonry-gallery__content h3 a:visited,
  .filter__horizontal-content h3 a:visited {
    color: $blue;
  }
  .footer-wrap a {
    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &:visited,
    &.selected {
      color: $blue !important;
    }
  }

  .uwg-links li a:visited {
    color: $blue;

    &:hover {
      color: $gray__xd;
    }
  }

  a.text-red:visited {
    color: $red;
  }

  .counter-wrap .counter-box:visited {
    text-decoration: none;
    color: $white;
  }
}

.container-small {
  max-width: 375px;
}

#map {
  width: 100%;
  height: 550px;
}

.ff-container.ff-loading-icon:before {
  display: none;
}

.video-popup-component a:not(.video-play) {
  display: block;
  width: 100%;
  height: 100%;
}
