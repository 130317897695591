/* --------------------------
 Header
----------------------------*/

.is-sticky {
    z-index: 200;
    position: fixed;
}

.header {
    padding: 27px 0 0;
    z-index: 200;
    position: fixed;
    top: 0;
    background-color: $white;
    height: 142px;
    transition: all 500ms cubic-bezier(0.55, 0, 0.1, 1);
    width: 100%;

    .home & {
        height: 155px;

        @media #{$mobile} {
            height: 68px;
        }
    }

    @media #{$mobile} {
        padding-top: 10px;
        height: 68px;
    }

    &.sticky {
        position: fixed;
        width: 100%;
        top: 0;
        height: 120px;
        padding-top: 10px;
        border-bottom: 1px solid $gray__xl;

        .home & {
            .logo-header img {
                @include transition(width 0.5s);
                margin-bottom: 28px;
                width: 94px;

                @media #{$mobile} {
                    margin-bottom: 0;
                }
            }
        }

        @media #{$mobile} {
            height: 68px;
        }
    }
}

.logo-header img {
    @include transition(width 0.5s);
    width: 88px;

    .home & {
        width: 170px;

        @media #{$tablet_only} {
            width: 100px;
            object-fit: cover;
        }
    }
}

.skip-links {
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    text-align: center;
    z-index: 1;

    li:before {
        content: "";
    }

    a {
        padding: 15px;
        background: $trueblack;
        color: $white;
        font-size: rem(20);
        line-height: 15px;
        position: absolute;
        top: -200px;
        left: 50%;
        display: inline-block;
        transform: translateX(-50%);


        &:focus {
            top: 10px;
            color: $white;
        }

        &:visited {
            color: $white !important;
        }
    }

    &.aznav_skip-links {
        z-index: 200;
    }

}

.header__right-top {
    width: 100%;
    display: inline-block;
    text-align: right;
    color: $gray__xd;
    font-size: rem(18);
    padding-bottom: 15px;

    @media #{$mobile} {
        text-align: center;
    }
}

.header__right-top-nav {
    list-style: none;
    margin: 0;
    padding: 0;

    @media #{$mobile} {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    >li {
        display: inline-block;
        margin-left: 30px;
        padding: 0 0 10px;
        font-family: $font_main_roman;
        color: #474c55;
        font-size: rem(18);
        margin-bottom: 0;

        &:before {
            content: "";
        }

        @media #{$mobile} {
            margin-left: 0;
            padding-bottom: 0;

            &.mobile-logo {
                margin-left: 50px;

                img {
                    width: 50px;
                    height: 53px;
                }
            }
        }

        a {
            text-decoration: none !important;
            border-bottom: 1px solid transparent;
            color: $gray__xd;
            display: block;

            &:hover, &:focus {
                color: $red;

                .text {
                    text-decoration: underline !important;
                }
            }

            .icon {
                padding-right: 8px;
                vertical-align: middle;

                &:before {
                    font-size: rem(30);
                }
            }

            @media #{$mobile} {
                .text {
                    display: none;
                }
            }
        }
    }
}

.header__right-dropdown {
    position: relative;

    @media #{$mobile} {
        position: static;

        &.show .icon-lock:before {
            content: "\e908";
            color: $gray__xd;
            font-size: rem(22);
            padding-left: 8px;
        }
    }

    .no-js &:hover {
        .dropdown-menu {
            display: block;
        }
    }
}

.header__right-search {
    float: right;
    margin-right: 50px;

    @media #{$mobile} {
        .search__close {
            display: block;
            width: 38px;

            &.open .icon.icon-magnifying-glass:before {
                content: "\e908";
                color: #474c55;
                font-size: rem(22);
            }
        }
    }
}

.header__right-top .dropdown-menu {
    background-color: $gray__xd;
    border-radius: 0;
    min-width: 120px;
    padding: 5px;
    margin-top: 10px;

    .no-js & {
        margin-top: 0;
    }

    li {
        font-size: rem(18);
        color: $white;
        padding: 0;
        line-height: 1.2;
        display: block;
        margin: 0;

        before {
            content: "";
        }
    }

    &:before {
        bottom: 100%;
        left: 28%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(71, 76, 85, 0);
        border-bottom-color: $gray__xd;
        border-width: 9px;
        margin-left: -9px;
    }

    a {
        color: $white;
        padding: 4px 10px;
        display: inline-block;

        &:hover,
        &:focus {
            color: $white;
            text-decoration: underline !important;
        }

        &:visited {
            color: $white;
        }
    }

    @media #{$mobile} {
        left: 0;
        top: 52px;
        width: 100%;
        padding: 10px;

        &:before {
            left: 28px;
        }
    }
}

.header__right {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
}

.header__right-nav {
    text-align: right;
    list-style: none;
    /*width: calc(100% - 130px);
    padding-right: 15px;*/
    display: flex;
    justify-content: flex-end;
    width: 100%;

    ul {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            padding: 0 25px;
            font-size: rem(26);
            font-weight: bold;

            a {
                color: $gray__xd;
                text-decoration: none;
                border-bottom: none;
                display: block;

                &:hover, &.is-selected, &:active {
                    color: $gray__xd;
                }

                &:hover, &:focus {
                    color: $red;
                    text-decoration: underline;
                }
            }
        }

        @media #{$mobile} {
            display: none;
        }
    }
}

.header__right-more {
    font-size: rem(26);
    font-weight: bold;
    color: $blue;
    /*width: 130px;
    position: relative;
    z-index: 10;*/
    margin-left: 10px;



    @media #{$mobile} {
        width: 30px;
        position: absolute;
        top: 40%;
        right: 20px;
        transform: translateY(-50%);
        z-index: 1;
        display: block;
    }

    .menu-bars-toggle {
        display: inline-block;
        vertical-align: middle;
        margin-left: 25px;
        position: relative;

        @media #{$mobile} {
            margin-left: 0;
            display: block;
            height: 25px;
        }

        .bar1,
        .bar2,
        .bar3 {
            width: 23px;
            height: 2px;
            background-color: $blue;
            display: block;
            transform: rotate(0deg);
            @include transition (.25s ease-in-out);
            transform-origin: left center;
            position: absolute;
            left: 0;
            z-index: 10;
            border-radius: 6px;
        }

        .bar1 {
            top: -12px;

            @media #{$mobile} {
                top: 0;
            }
        }

        .bar2 {
            width: 33px;
            top: -2px;

            @media #{$mobile} {
                top: 8px;
            }
        }

        .bar3 {
            top: 8px;
            width: 28px;

            @media #{$mobile} {
                top: 16px;
            }
        }
    }

    @media #{$mobile} {
        .open .menu-bars-toggle {
            .bar1,.bar2,.bar3 {
                background-color: $gray__xd;
            }

            .bar2 {
                width: 0%;
                opacity: 0;
            }

            .bar1 {
                transform: rotate(45deg);
                top: 0;
                width: 30px;
            }

            .bar3 {
                transform: rotate(-45deg);
                top: 21px;
                width: 30px;
            }
        }
    }

    .menu__toggle {
        color: $blue;
        text-decoration: none;
        display: block;
        vertical-align: middle;
        position: relative;
        padding-right: 38px;

        &:hover, &:focus {
            color: $red;

            .text {
                text-decoration: underline;
            }
        }

        &:hover .menu-bars-toggle > span {
            background-color: $red;
        }


        @media #{$mobile} {
            display: block;

            .text {
                display: none;
            }
        }
    }

    .change .bar1 {
        -webkit-transform: rotate(-45deg) translate(-9px, 6px);
        transform: rotate(-45deg) translate(-9px, 6px);
    }

    .change .bar2 {
        opacity: 0;
    }

    .change .bar3 {
        -webkit-transform: rotate(45deg) translate(-8px, -8px);
        transform: rotate(45deg) translate(-8px, -8px);
    }
}

.header__search {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $blue;
    width: 100%;
    height: 100vh;
    text-align: left;
    z-index: 100;

    @media (orientation: landscape) {
        overflow-y: auto;
    }

    @media #{$mobile} {
        top: 68px;
        overflow-y: auto;
        height: calc(100vh - 68px);
    }

    .search-inbox {
        max-width: 790px;
        margin: 0 auto;
        width: 100%;
        display: table;
        padding: 30px;

        @media (orientation: landscape) {
            padding: 30px 5px 100px;
        }
        @media #{$mobile} {
            padding: 30px 5px 50px;
        }
    }

    .result-title {
        font-size: rem(24);
        font-weight: 500;
        color: $white;
        margin: 0 0 15px;
    }

    .search-title {
        font-size: rem(50);
        font-family: $font__heading;
        color: $white;
        line-height: 1.2;
        margin: 90px 0 60px;

        @media (orientation: landscape) {
            margin: 20px 0;
        }

        @media #{$mobile} {
            font-size: rem(32);
            margin: 0 0 10px;
        }
    }

    .search__form {
        margin-bottom: 60px;

        .error-msg {
            color: #ff5e5e;
            font-size: rem(14);
            padding: 5px 0;
            display: inline-block;
        }

        @media #{$mobile} {
            margin-bottom: 10px;
        }
    }

    .search__input {
        min-height: 60px;
        padding: 10px 70px 10px 20px;
        font-size: rem(28);
        font-family: $font_main_roman;
        color: $gray__xd;
        width: 100%;
        border: 0;
        border-radius: 0;

        &::-webkit-input-placeholder {
            color: $gray__xd;
        }

        &::-moz-placeholder {
            color: $gray__xd;
        }

        &:-ms-input-placeholder {
            color: $gray__xd;
        }

        &:-moz-placeholder {
            color: $gray__xd;
        }

        @media #{$mobile} {
            margin-bottom: 20px;

            &.d-inline-block {
                display: block;
                min-height: 48px;
                font-size: rem(20);
                padding: 10px 45px 10px 15px;
            }
        }
    }

    .search__submit {
        position: absolute;
        top: 0px;
        right: 15px;
        border: none;
        border-radius: 0;
        background: transparent;
        font-size: rem(30);
        color: $gray__xd;
        height: 60px;
        width: 68px;
        line-height: 0.5;
        text-align: center;
        cursor: pointer;

        span {
            position: absolute;
            width: 30px;
            height: 30px;
            top: 15px;
            right: 20px;
        }

        &:hover,
        &:focus {
            color: $red;
            opacity: 1;
        }

        @media #{$mobile} {
            font-size: rem(24);
            height: 50px;
            width: 55px;

            span {
                top: 14px;
                right: 12px;
            }
        }
    }

    .search-result-section {
        color: $white;
    }
}

.header__search-list {
    margin: 0;
    padding: 0;
    list-style: none;

    @media #{$mobile} {
        margin-bottom: 30px;
    }

    li {
        font-size: rem(16);
        color: $white;
        display: block;
        padding: 0 0 8px 0;

        &:before {
            content: "\f105";
            font-size: rem(20);
            font-family: 'Font Awesome 6 Pro';
            font-weight: 900;
            padding-right: 10px;
        }

        a, a:visited {
            color: $white;
            text-decoration: none;
            border-bottom: 1px solid;
            transition: none;

            &:hover, &:focus {
                color: $white;
                text-decoration: underline;
                border-bottom: 1px solid transparent;
            }
        }
    }
}

.megamenu__wrap {
    position: fixed;
    background-color: $white;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 10;
    transform: translateX(100%);
    transition: 0.35s cubic-bezier(0.4, 0, 0.2, 1);

    &.active {
        @include translateX(0);

        .row {
            height: 100%;
        }
    }

    @media #{$mobile} {
        overflow: hidden;
    }

    @media #{$mobile} {
        top: 58px;
        height: calc(100vh - 68px);
        background: $gray__xl;
        position: absolute;
        padding-bottom: 0;

        >.row {
            height: 100%;
        }

        .iphoneDevice & {
            //height: calc(100vh - 138px);
        }

        .androidDevice & {
            //height: calc(100vh - 160px);
        }
    }

    @media #{$mobile_iphonx}, #{$mobile_iphonXR} {
        .iphoneDevice & {
            //height: calc(100vh - 180px);
        }
    }

    @media #{$mobile_iphonx_landscape} {
        .iphoneDevice & {
            //height: calc(100vh - 118px);
        }
    }

    @media #{$mobile_landscape} {
        .iphoneDevice & {
            //height: calc(100vh - 68px);
        }

        .androidDevice & {
            //height: calc(100vh - 80px);
        }
    }

    @media #{$mobile_max} {
        .androidDevice & {
            //height: calc(100vh - 123px);
        }
    }
    @media #{$mobile_iphonXR_landscape} {
        .iphoneDevice & {
            height: 100vh;
            right: -10%;

            &.active {
                right: 0;
            }
        }
    }

    @media #{$tablet_min} {
        &:after {
            content: "";
            background: $gray__xl;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 32.6%;
            z-index: -1;
        }

        >.row {
            overflow-y: auto;
            position: relative;
        }
    }

    @media #{$desktop} {
        //&:after{width: 33.6%;}
    }

    @media #{$galaxy_tab_landscape} {
        &:after{width: 32.6%;}
    }

    .megamenu-title {
        font-size: rem(100);
        font-family: $font__heading_plume;
        font-weight: 500;
        line-height: 1.2;
        color: $red;
        margin: 0 0 30px;
        text-transform: uppercase;

        @media #{$tablet_lg} {
            font-size: rem(75);
        }
    }

    p {
        max-width: 500px;
        font-size: rem(18);
        line-height: 1.2;
        font-family: $font_main_roman;
        color: $gray__xd;
        margin: 0 0 20px;

        strong {
            font-size: rem(20);
            font-weight: bold;
            line-height: 1.5;
        }
    }

    .left-warp {
        padding: 100px 200px 100px 100px;

        @media #{$desktop_lg} {
            padding: 100px 60px;
        }

        @media #{$tablet_lg} {
            padding: 10% 5%;
        }

        @media #{$max_height} {
            padding: 8% 6% 9%;
        }

        .col-sm-4 {
            padding-right: 5px;
            max-width: 220px;
        }

        @media #{$desktop} {
            height: 100vh;
        }

        @media #{$galaxy_tab_landscape} {
            height: auto;
            padding: 8% 6% 15%;
        }

        @media #{$tablet} {
            padding-bottom: 110px;
        }

    }

    .btn-margin {
        margin: 0 0 60px;
        padding-top: 20px;

        @media #{$tablet} {
            margin-bottom: 30px;
        }
    }

    .three-box {
        display: inline-flex;
        flex-wrap: wrap;
    }

    .program-box {
        margin-bottom: 8px;
        font-size: rem(24);
        font-family: $font_main_bdCon;
        color: $white;
        letter-spacing: -1px;
        padding: 30px;
        text-transform: uppercase;
        height: 100%;
        word-break: break-word;
        display: block;

        @media #{$tablet} {
            padding: 20px;
        }

        &:hover, &:focus, &:visited, &.selected {
            text-decoration: underline;
            color: $white;
        }

        &.bg-primary:hover,
        &.bg-primary:focus,
        &.bg-primary:visited {
            background: $red !important;
        }

        &.bg-gray {
            color: $gray__xd;

        }
    }

    .right-warp {
        z-index: 20;
        padding: 100px 100px 100px 50px;
        display: none;

        @media #{$desktop_lg} {
            padding: 100px 40px 100px 30px;
        }

        @media #{$tablet_lg} {
            padding: 100px 20px 100px 10px;
        }

        @media #{$max_height} {
            padding: 80px 20px 80px 10px;

        }

        @media #{$mobile} {
            padding: 0 20px 30px;
            height: 100%;
            padding-bottom: 0;
        }

        @media #{$desktop} {
            height: 100vh;
        }

        @media #{$tablet_landscape} {
            height: 94%;
        }
    }

    &.active {
        .right-warp {
            display: flex;
            flex-direction: column;
            height: 100vh;

            @media #{$max_height} {
                height: 100%;
            }

            @media #{mobile} {
                padding-top: 0;
            }
        }
    }

    &:not(.active) {
        .btn-primary,
        .program-box,
        .menu__breadcrumbs,
        .menu__close {
            display: none !important;
        }
    }
}

.menu__breadcrumbs {
    font-size: rem(15);
    font-family: $font_main_roman;
    color: $gray__xd;
    line-height: 1.2;
    margin: 0 0 40px 0;
    padding: 0;
    list-style: none;

    @media #{$mobile} {
        margin-bottom: 20px;
    }

    li {
        display: inline-block;
        margin: 0 4px 7px;
        color: $gray__xd;

        &:only-child a span {
            color: $gray__xd;

            &:hover,
            &:focus,
            &:active,
            &.selected {
                color: $gray__xd;
            }
        }

        &:last-child a {
            color: $gray__xd;
            pointer-events: none;
            text-decoration: none;
            border-bottom: none;

            &:hover,
            &:active,
            &:focus {
                color: $gray__xd;
                cursor: default;
            }
        }

        a {
            color: $blue;
            text-decoration: underline;

            &:hover,
            &:hover span,
            &focus,
            &:focus span {
                color: $red;
                text-decoration: underline;
            }

            &.is-hidden {
                display: none;
            }
        }

        span {
            color: $gray__xd;
        }

        +li {
            &:before {
                content: "/";
                padding-right: 8px;
            }
        }
    }

    .active {
        a {
            color: $blue;

            &:hover {
                color: $red;
            }
        }
    }
}

.mega-menu-bottom-links {
    margin-top: auto;
    z-index: 100;

    .iphoneDevice & {
        @media #{$mobile} {
            bottom: 0;
        }
    }

    @media #{$desktop} {
        position: absolute;
        bottom: 30px;
    }
    @media #{$max_height} {
        position: initial;
        bottom: 0;
    }

    @media #{$tablet_only} {
        //position: absolute;
        bottom: 120px;
    }

    @media #{$tablet_landscape} {
        bottom: 150px;
    }

    @media #{$galaxy_tab_landscape} {
        //bottom: 120px;
        //bottom: 0;
    }

    @media #{$mobile} {
        text-align: left;
        position: sticky;
        width: 100%;
        bottom: 0;
        left: 0;
        background: $gray__xd;
        z-index: 100;
        margin-left: -20px;
        margin-right: -20px;
        margin-top: 0;
        margin-bottom: 10px;
        width: calc(100% + 40px);

        @media all and (-ms-high-contrast:none) {
            position: fixed;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        @media #{$max_height} {
            padding-bottom: 10px;
        }
        @media #{$mobile} {
            padding-bottom: 0;
        }

        li {
            display: inline-block;
            font-size: rem(26);
            font-weight: bold;
            color: $blue;

            @media #{$tablet_lg} {
                font-size: rem(20);
            }

            @media #{$mobile} {
                float: left;
                width: 33.33%;
                text-align: center;
            }

            +li {
                margin-left: 40px;

                @media #{$tablet_only} {
                    margin-left: 20px;
                }

                @media #{$mobile} {
                    margin-left: 0;
                    border-left: 1px solid rgba($white,.8);
                }
            }

            a {
                color: $blue;
                text-decoration: none;
                border-bottom: none;

                &:hover {
                    color: $red;
                    text-decoration: underline;
                }

                &:visited {
                    color: $gray__xd;
                    text-decoration: underline;
                }

                @media #{$mobile} {
                    color: $white;
                    display: block;
                    padding: 10px;

                    &:hover, &:visited, &.selected {
                        text-decoration: none;
                        color: $light-gray;
                    }

                    &:hover {
                        background: $red;
                        text-decoration: none;
                        color: $white;
                    }
                }
            }
        }
    }
}

.header .close {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 42px;
    height: 42px;
    opacity: 1;
    z-index: 1;

    &:before,
    &:after {
        position: absolute;
        left: 20px;
        content: ' ';
        height: 42px;
        width: 2px;
        background-color: $white;

        @media #{$mobile} {
            height: 30px;
        }
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }

    &:hover {
        opacity: 0.5;
    }
}

.header .menu__close {
    z-index: 200;

    &:before,
    &:after {
        background-color: $trueblack;
    }
}
