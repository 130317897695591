/* --------------------------
 Footer
---------------------------- */
.footer-social-section {
  background-color: $gray__xl;
  padding: 35px 0;
  position: relative;
  margin-top: 100px;

  @media #{$mobile} {
    margin-top: 50px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    text-align: center;

    li {
      display: inline-block;
      padding: 0 20px;

      @media #{$mobile_sm} {
        padding: 0 6px;
      }

      @media #{$mobile} {
        padding: 5px 12px;
      }

      a {
        color: $red;
        font-size: rem(32);
        text-decoration: none;

        .fa,
        .fas,
        .fal,
        .fad,
        .fak {
          font-size: rem(35);
        }

        &:visited {
          color: $red;
        }

        &:hover {
          color: $blue;
        }

        @media #{$mobile} {
          font-size: rem(25);

          .fa,
          .fas,
          .fal,
          .fad,
          .fak {
            font-size: rem(30);
          }
        }
      }
    }
  }
}

.js-back-to-top-btn {
  background: $red;
  border: 2px solid $white;
  width: 66px;
  height: 66px;
  position: absolute;
  right: 0;
  top: -66px;
  color: $white;
  line-height: 1.05;
  font-size: rem(50);
  text-align: center;
  z-index: 1;
  display: inline-block;
  cursor: pointer;

  .fa,
  .fas,
  .fal,
  .fad,
  .fak {
    color: $white;
  }

  &:hover {
    background: $blue;
    color: $white;
    border: 2px solid $blue;

    .fa,
    .fas,
    .fal,
    .fad,
    .fak {
      color: $white;
    }
  }

  @media #{$tablet} {
    width: 50px;
    height: 50px;
    font-size: rem(30);
    top: -50px;
    line-height: 1.3;
  }

  @media #{$mobile} {
    width: 40px;
    height: 40px;
    font-size: rem(30);
    top: -40px;
    line-height: 1;
  }
}

.footer-wrap {
  padding: 40px 0;

  @media #{$mobile} {
    padding: 20px 0;

    .col-md-2,
    .col-md-3,
    .col-md-5 {
      padding: 0;
    }
  }

  h3 {
    color: $gray__xd;
    font-size: rem(18);
    font-weight: bold;
    margin: 0 0 8px;

    @media #{$tablet_min} {
      pointer-events: none;
    }

    span.fa {
      display: none;

      @media #{$mobile} {
        display: block;
        float: right;
        font-size: rem(20);
      }
    }

    @media #{$mobile} {
      background: $gray__xl;
      padding: 15px 20px;

      &:hover {
        color: $blue;
      }

      &.show {
        span.fa:before {
          content: "\f106";
        }
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      color: $blue;
      font-size: rem(18);
      font-family: $font_main_roman;
      line-height: 1.5;
      padding: 3.5px 0;

      @media #{$mobile} {
        font-size: rem(16);
      }

      a {
        color: $blue;
        display: block;
        text-decoration: none;

        &:hover,
        &:focus,
        &.selected {
          color: $red;
          text-decoration: underline;
        }

        &:visited {
          color: $gray__xd;
          text-decoration: underline;
        }
      }
    }
  }

  .col-content {
    @media #{$mobile} {
      padding: 20px;
      display: none;
    }

    @media #{$tablet_min} {
      display: block !important;
    }
  }

  .locations {
    .address {
      a {
        color: $blue;
        display: inline-block;
        display: block;
        font-size: rem(18);
        font-family: $font_main_roman;
        line-height: 1.25;
        padding: 5px 0;

        &:hover,
        &:focus,
        &.selected {
          color: $red;
          text-decoration: underline;
        }

        &:visited {
          color: $gray__xd;
          text-decoration: underline;
        }

        @media #{$mobile} {
          font-size: rem(16);
        }
      }
    }

    @media #{$tablet_max} {
      .address + .address {
        margin-top: 20px;
      }
    }

    .col-content {
      @media #{$tablet_min} {
        display: flex !important;
      }
    }
  }

  .bottom-line {
    padding: 50px 0 34px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: center;

      li {
        font-size: rem(16);
        font-family: $font_main_roman;
        color: $gray__l;
        display: inline-block;
        padding: 0 10px;

        a {
          color: $blue;
          text-decoration: none;

          &:hover,
          &:focus,
          &.selected {
            color: $red;
            text-decoration: underline;
          }

          &.copyright {
            color: $gray__l;

            &:hover,
            &:focus,
            &.selected {
              color: $red;
            }

            &:visited {
              color: $gray__xd;
              text-decoration: underline;
            }
          }
        }

        @media #{$tablet} {
          font-size: rem(14);
        }

        @media #{$mobile} {
          font-size: rem(12);
          padding: 0 5px;
          padding-top: 15px;
        }
      }
    }

    @media #{$mobile} {
      padding: 20px 0;
    }
  }

  .west-georgia-logo {
    text-align: center;
    width: 100%;

    img {
      max-width: 100%;
      width: 258px;
    }
  }
}
