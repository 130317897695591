/* Library Page */
.uwg-search__sidebar {
    padding: 50px 70px 50px 100px;    
    width: 100%;

    @media #{$desktop_xlg} {
        padding: 6% 12% 6% 15%;
    }

    @media #{$desktop_lg} {
        padding: 30px 50px;
    }

    @media #{$tablet} {
        padding: 30px 20px 30px 30px;
    }

    @media #{$mobile} {
        padding: 30px 20px 40px;
    }

    h2 {
        font-size: rem(34);
        font-weight: bold;
        margin-bottom: 25px;
        width: 100%;

        @media #{$tablet} {
            font-size: rem(27);
            margin-bottom: 20px;
            line-height: 1;
        }
    }

    form {
        width: 100%;
        padding-bottom: 40px;
    }

    h3 {
        font-size: rem(25);
        font-weight: bold;
        margin-top: 40px;
        margin-bottom: 14px;

        @media #{$tablet} {
            font-size: rem(20);
            margin-top: 25px;
        }
    }

    .search-list {
        font-family: $font_main_roman;
        padding-bottom: 60px;

        li {
            margin-bottom: 10px;
        }

        @media #{$tablet} {
            padding-bottom: 40px;
            font-size: rem(13);
        }
    }
}

.uwg-search__sidebar-form {
    .uwg-search__sidebar-form__wrap,
    .uwg-search__sidebar-input__wrap {
        position: relative;
    }

    label {
        font-size: rem(18);
        margin-bottom: 20px;
        font-weight: normal;

        @media #{$tablet_min} {
            max-width: 500px;
        }
    }

    .mw-500 {
        @media #{$tablet_min} {
            max-width: 500px;
        }
    }

    select {
        background: $white url(../images/select-arrow.png) no-repeat 96% center;
        border: none;
        box-shadow: none;
        color: $gray__xd;        
        font-size: rem(20);
        border-radius: 0;
        padding: 12px 45px 12px 20px;
        margin-bottom: 20px;
        width: 100%;
        min-height: 60px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
         -o-appearance: none;
            appearance: none;

        &::-ms-expand {
            display: none;
        }

        @media #{$tablet} {
            background-position: 97% center;
            font-size: rem(16);
            padding: 10px 40px 10px 20px;
            margin-bottom: 15px;
            min-height: 48px;
        }
    }

    .search__input {
        background: $white;
        border: none;
        box-shadow: none;
        font-family: $font_main_roman;
        font-size: rem(20);
        padding: 12px 68px 12px 20px;
        margin-bottom: 20px;
        width: 100%;
        min-height: 60px;

        @media #{$tablet} {
            font-size: rem(16);
            padding: 10px 60px 10px 20px;
            margin-bottom: 15px;
            min-height: 48px;
        }

        &::-webkit-input-placeholder {
            color: $gray__xd; 
            opacity: 1;
        }

        &::-moz-placeholder {
            color: $gray__xd; 
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: $gray__xd; 
            opacity: 1;
        }
        &:-moz-placeholder {
            color: $gray__xd; 
            opacity: 1;
        }
    }

    .search__submit {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        border-radius: 0;
        background: transparent;
        font-size: rem(30);
        color: $gray__xd;
        height: 60px;
        width: 68px;
        line-height: 0.5;
        padding: 0;
        text-align: center;
        cursor: pointer;

        @media #{$tablet} {
            font-size: rem(22);
            height: 48px;
            width: 55px;
        }
       
        &:hover,
        &:focus {
            color: $red;
        }
    }

    .text-red {
        font-weight: 500;

        @media #{$tablet} {
            font-size: rem(13);
        }
    }
}

.help-link {
    display: inline-block;
    font-weight: bold;
    margin-top: 15px;

    @media #{$tablet} {
        font-size: rem(13);
    }

    &:hover,
    &:focus, &:visited {
        color: $blue;
        text-decoration: underline;
    }
}

.contact-info-wrap {
    padding: 40px 40px 30px;

    &.bg-primary, &.bg-secondary, &.bg-cool-gray {
        .special-heading {
            color: $white;
        }
    }

    .special-heading {
        font-family: $font_main_bdCon;
        font-size: rem(38);
        color: $blue;
        text-transform: uppercase;
        margin-bottom: 20px;

        @media #{$tablet} {
            font-size: rem(30);
            margin-top: 5px;
        }
    }

    p {
        font-size: rem(16);
    }

    @media #{$tablet} {
        padding: 35px;
    }

    @media #{$mobile} {
        padding: 30px;
        margin-bottom: 40px;
    }
}