$font__main: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font__heading: 'permanent_markerregular', "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font__heading_plume: 'plumeplume', "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font__helveticaBlkCon: "Helvetica_Black_Condensed", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font_main_roman: "Helvetica_Roman", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font_main_bdCon: "HelveticaNeueLTStdBoldCondensed", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font_main_hvCon: "Helvetica_Heavy_Condensed", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font_main_con: "HelveticaNeueLTStdCon", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$browser-context: 16; // Default font-size of 16px
@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

body {
  color: $gray__xd;
  font-family: $font__main;
  font-size: rem(16);
  font-weight: 500;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

h1, h2, h3, h4, h5, h6 {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    word-break: break-word;
    word-wrap: break-word;
  }
}

h1, .h1 {
  font-size: rem(36);
  font-weight: bold;
  letter-spacing: -0.8px;
  line-height: 1.1;
  margin-top: 0;

  .font__plume, &.font__plume {
    font-size: rem(56);

    @media #{$tablet} {
      font-size: rem(50);
    }
  }


  @media #{$tablet} {
    font-size: rem(30);
  }

}

h2, .h2 {
  font-family: $font__main;
  font-size: rem(25);
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -0.74px;
  margin-bottom: 15px;

  .font__plume, &.font__plume {
    font-size: rem(45);

    @media #{$tablet} {
      font-size: rem(40);
    }
  }

  &.special-heading {
    font-size: rem(38);
    font-family: $font_main_con;
    line-height: 1;
    letter-spacing: -1px;
    text-transform: uppercase;
    font-weight: normal;

    @media #{$tablet} {
      font-size: rem(30);
    }
  }

  @media #{$tablet} {
    font-size: rem(20);
  }

}

h3, .h3 {
  font-family: $font__main;
  font-size: rem(22);
  font-weight: bold;
  letter-spacing: -0.88px;
  line-height: 1.3;
  margin-bottom: 15px;

  .font__plume, &.font__plume {
    font-size: rem(42);

    @media #{$tablet} {
      font-size: rem(38);
    }
  }

  @media #{$tablet} {
    font-size: rem(18);
  }

}

h4, .h4 {
  font-size: rem(20);
  line-height: 1.2;
  color: $gray__l;

  .font__plume, &.font__plume {
    font-size: rem(40);

    @media #{$tablet} {
      font-size: rem(36);
    }
  }

  @media #{$tablet} {
    font-size: rem(16);
  }

}

h5, .h5 {
  font-size: rem(18);
  line-height: 1.5;
  letter-spacing: -0.65px;
  margin-bottom: 5px;

  .font__plume, &.font__plume {
    font-size: rem(38);

    @media #{$tablet} {
      font-size: rem(34);
    }
  }

  @media #{$tablet} {
    font-size: rem(14);
  }

}

.letter-spacing-1 {
  letter-spacing: 1px !important;
}

.letter-spacing-2 {
  letter-spacing: 2px !important;
}

.letter-spacing-3 {
  letter-spacing: 3px !important;
}

legend {
  @media #{$mobile} {
    font-size: 1.3rem;
  }
}